angular.module("ramperv3").controller("RelatorioController", [
    "$rootScope",
    "$scope",
    "$http",
    function ($rootScope, $scope, $http) {
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        $("#menuPrincipal").show();
        $rootScope.mostrarMenu = true;
        $scope.menuNavAberto = true;
        $scope.rankingMenuAberto = true;
        $scope.mostraAddCard = false;
        $scope.mostraFiltro = false;
        $scope.menuVisaoGeral = true;
        $scope.menuProspeccao = false;
        $scope.mostraFiltroUsuarios = false;
        $scope.mostraFiltroCadencias = false;
        $scope.mostraFiltroMes = false;
        $scope.mostraAtividadesRanking = false;
        $scope.atividadesSelecionadas = 0;
        $scope.usuariosSelecionados = 0;
        $scope.cadenciasSelecionadas = 0;
        $scope.atividadesRankingSelecionadas = 0;
        $scope.atividadeRanking = "";
        $scope.timeRanking = [];
        $scope.dataRanking = [];
        $scope.timeCadencia = [];
        var meses = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        $scope.mesFront = meses[new Date().getMonth()];
        $scope.anoFront = new Date().getFullYear();
        if ($scope.mesFront === "Janeiro")
            $scope.mesAnteriorFront = "Dezembro";
        else
            $scope.mesAnteriorFront = meses[new Date().getMonth() - 1];
        var idMesInicial = "mes" + new Date().getMonth() + $scope.anoFront;
        var mesInicial = new Date().getMonth();
        $scope.valorDoCard = 0;
        $scope.tituloDoCard = "";
        $scope.valorAnteriorDoCard = 0;
        $scope.percMudanca = 0;
        $scope.maiorQueMil = false;
        $scope.color = { "background-color": "#75c18d" };
        $scope.order = "";
        $scope.cards = [];
        $scope.usuariosTime = [];
        $scope.cadenciasTime = [];
        $scope.mesesFiltro = [];
        var filtroUsuarios = [];
        var filtroCadencias = [];
        var filtroMeses = [];
        var popupsCard = document.getElementById("addCard");
        var popupsFiltro = document.getElementById("conjunto-filtro");
        var popupsRanking = document.getElementById("abreRanking");
        $scope.mouseOut = true;
        // Cards de Contatos
        $scope.cardContatosCapturados = false;
        $scope.cardContatosAbordados = false;
        $scope.cardContatosEmailsAbertos = false;
        $scope.listaContatosCapturados = [];
        $scope.listaContatosAbordados = [];
        $scope.listaContatosEmailsAbertos = [];
        $scope.listaContatosCapturadosMesAnterior = [];
        $scope.listaContatosAbordadosMesAnterior = [];
        $scope.listaContatosEmailsAbertosAnterior = [];
        // Cards de Confiabilidade
        $scope.cardConfiabilidadeAltaAtivo = false;
        $scope.cardConfiabilidadeMediaAtivo = false;
        $scope.cardConfiabilidadeBaixaAtivo = false;
        $scope.cardConfiabilidadeSemAtivo = false;
        $scope.listaConfiabilidadeAlta = [];
        $scope.listaConfiabilidadeMedia = [];
        $scope.listaConfiabilidadeBaixa = [];
        $scope.listaConfiabilidadeSem = [];
        $scope.listaConfiabilidadeAltaMesAnterior = [];
        $scope.listaConfiabilidadeMediaMesAnterior = [];
        $scope.listaConfiabilidadeBaixaMesAnterior = [];
        $scope.listaConfiabilidadeSemMesAnterior = [];
        // Cards de Respostas
        $scope.cardRespostaPositivaAtivo = false;
        $scope.cardRespostaNeutraAtivo = false;
        $scope.cardRespostaNegativaAtivo = false;
        $scope.cardTotalRespostaAtivo = false;
        $scope.listaRespostaPositiva = [];
        $scope.listaRespostaNeutra = [];
        $scope.listaRespostaNegativa = [];
        $scope.listaRespostaTotal = [];
        $scope.listaRespostaPositivaMesAnterior = [];
        $scope.listaRespostaNeutraMesAnterior = [];
        $scope.listaRespostaNegativaMesAnterior = [];
        $scope.listaRespostaTotalMesAnterior = [];
        // Card de Cadencia
        $scope.cardCadenciaAtivo = false;
        $scope.listaCadencia = [];
        $scope.listaCadenciaMesAnterior = [];
        // Cards de Emails
        $scope.cardEmailEnviadoAtivo = false;
        $scope.cardEmailEntregueAtivo = false;
        $scope.cardEmailBounceAtivo = false;
        $scope.cardEmailAbertoAtivo = false;
        $scope.listaEmailEnviado = [];
        $scope.listaEmailEntregue = [];
        $scope.listaEmailBounce = [];
        $scope.listaEmailAberto = [];
        $scope.listaEmailEnviadoMesAnterior = [];
        $scope.listaEmailEntregueMesAnterior = [];
        $scope.listaEmailBounceMesAnterior = [];
        $scope.listaEmailAbertoMesAnterior = [];
        // Card de Janela de Atenção
        $scope.cardJanelaOportunidadeAtivo = false;
        $scope.listaJanelaOportunidade = [];
        $scope.listaJanelaOportunidadeMesAnterior = [];
        // Cards do Conectar
        $scope.cardTempoLigacaoAtivo = false;
        $scope.cardConexaoConectarAtivo = false;
        $scope.cardConversaoConectarAtivo = false;
        $scope.listaTempoLigacao = [];
        $scope.listaConexaoConectar = [];
        $scope.listaConversaoConectar = [];
        $scope.listaTempoLigacaoMesAnterior = [];
        $scope.listaConexaoConectarMesAnterior = [];
        $scope.listaConversaoConectarMesAnterior = [];
        // Grafico
        $scope.abrirGrafico = false;
        $scope.idGraficoAberto = 0;
        $scope.textoGraficoAcumulativo = false;
        $scope.textoGraficoComparativo = false;
        // Ranking
        $scope.listaAtividadesRanking = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
        ];
        $scope.listaCheckAtividadesRankings = [
            "checkContatosCapturadosRanking",
            "checkContatosAbordadosRanking",
            "checkContatosEmailsAbertosRanking",
            "checkConfiabilidadeAltaRanking",
            "checkConfiabilidadeMediaRanking",
            "checkConfiabilidadeBaixaRanking",
            "checkConfiabilidadeSemRanking",
            "checkCadenciaAtivaRanking",
            "checkEmailEnviadoRanking",
            "checkEmailEntregueRanking",
            "checkEmailBounceRanking",
            "checkEmailAbertoRanking",
            "checkRespostaPositivaRanking",
            "checkRespostaNeutraRanking",
            "checkRespostaNegativaRanking",
            "checkTotalRespostaRanking",
            "checkJanelaOportunidadeRanking",
            "checkTempoLigacaoRanking",
            "checkConexaoConectarRanking",
            "checkConversaoConectarRanking",
        ];
        $scope.listaRanking = [];
        $scope.dadosRanking = [];
        $scope.dadosRankingShow = [];
        $scope.controleRankingShow = "todos";
        $scope.textoRankingShow = "mais";
        $scope.ativRankingEscolhido = "";
        $scope.rankingLoader = false;
        $scope.montaCardsNaTelaPrimeiraVez = false;
        $scope.atvRankingSelecionado = "";
        $scope.dataFiltro = new Date();
        $rootScope.redirectRelatorio();
        $scope.montaTela = function (novaTela) {
            $("#menuNavVisaoGeral").removeClass("subMenuRelatorioBold");
            $("#menuNavVisaoGeral").removeClass("liRelatorioAtivo");
            $("#menuNavMetasProspeccao").removeClass("subMenuRelatorioBold");
            $("#menuNavMetasProspeccao").removeClass("liRelatorioAtivo");
            if (novaTela == "VisaoGeral") {
                $scope.menuVisaoGeral = true;
                $scope.menuProspeccao = false;
                $("#menuNavVisaoGeral").addClass("subMenuRelatorioBold");
                $("#menuNavVisaoGeral").addClass("liRelatorioAtivo");
            }
            else if (novaTela == "Metas") {
                $scope.menuVisaoGeral = false;
                $scope.menuProspeccao = true;
                $("#menuNavMetasProspeccao").addClass("subMenuRelatorioBold");
                $("#menuNavMetasProspeccao").addClass("liRelatorioAtivo");
            }
        };
        $scope.montaTela("VisaoGeral");
        function refresh() {
            if ($scope.cardContatosCapturados) {
                consultarContatosCapturados(filtroUsuarios, filtroMeses, filtroCadencias);
            }
            if ($scope.cardContatosAbordados) {
                consultarContatosAbordados(filtroUsuarios, filtroMeses, filtroCadencias);
            }
            if ($scope.cardContatosEmailsAbertos) {
                consultarContatosEmailsAbertos(filtroUsuarios, filtroMeses, filtroCadencias);
            }
            if ($scope.cardConfiabilidadeAltaAtivo) {
                consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "alta");
            }
            if ($scope.cardConfiabilidadeMediaAtivo) {
                consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "media");
            }
            if ($scope.cardConfiabilidadeBaixaAtivo) {
                consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "baixa");
            }
            if ($scope.cardConfiabilidadeSemAtivo) {
                consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "sem");
            }
            if ($scope.cardRespostaPositivaAtivo) {
                consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "P");
            }
            if ($scope.cardRespostaNeutraAtivo) {
                consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "N");
            }
            if ($scope.cardRespostaNegativaAtivo) {
                consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "C");
            }
            if ($scope.cardTotalRespostaAtivo) {
                consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "T");
            }
            if ($scope.cardCadenciaAtivo) {
                consultarCadenciasAtivas(filtroUsuarios, filtroMeses, filtroCadencias);
            }
            if ($scope.cardEmailEnviadoAtivo) {
                consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "ENV");
            }
            if ($scope.cardEmailEntregueAtivo) {
                consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "E");
            }
            if ($scope.cardEmailBounceAtivo) {
                consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "B");
            }
            if ($scope.cardEmailAbertoAtivo) {
                consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "A");
            }
            if ($scope.cardJanelaOportunidadeAtivo) {
                consultarJanelasOportunidades(filtroUsuarios, filtroMeses, filtroCadencias);
            }
            // if ($scope.cardTempoLigacaoAtivo) {
            // }
            // if ($scope.cardConexaoConectarAtivo) {
            // }
            // if ($scope.cardConversaoConectarAtivo) {
            // }
            // if ($scope.idGraficoAberto === 1)
            //     $scope.abreGraficoAcumulativo();
            // if ($scope.idGraficoAberto === 2)
            //     $scope.abreGraficoComparativo();
        }
        $scope.adicionaCard = function (novoCard, salvarCard) {
            switch (novoCard) {
                case "TODOS":
                    // Cards de Contatos
                    if (!$scope.cardContatosCapturados) {
                        $scope.cardContatosCapturados = true;
                        $scope.checkOption("checkContatosCapturados");
                        consultarContatosCapturados(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardContatosAbordados) {
                        $scope.cardContatosAbordados = true;
                        $scope.checkOption("checkContatosAbordados");
                        consultarContatosAbordados(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardContatosEmailsAbertos) {
                        $scope.cardContatosEmailsAbertos = true;
                        $scope.checkOption("checkContatosEmailsAbertos");
                        consultarContatosEmailsAbertos(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    // Cards de Confiabilidade
                    if (!$scope.cardConfiabilidadeAltaAtivo) {
                        $scope.cardConfiabilidadeAltaAtivo = true;
                        $scope.checkOption("checkConfiabilidadeAlta");
                        consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "alta");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardConfiabilidadeMediaAtivo) {
                        $scope.cardConfiabilidadeMediaAtivo = true;
                        $scope.checkOption("checkConfiabilidadeMedia");
                        consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "media");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardConfiabilidadeBaixaAtivo) {
                        $scope.cardConfiabilidadeBaixaAtivo = true;
                        $scope.checkOption("checkConfiabilidadeBaixa");
                        consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "baixa");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardConfiabilidadeSemAtivo) {
                        $scope.cardConfiabilidadeSemAtivo = true;
                        $scope.checkOption("checkConfiabilidadeSem");
                        consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "sem");
                        $scope.atividadesSelecionadas += 1;
                    }
                    // Card de Cadencias Ativas
                    if (!$scope.cardCadenciaAtivo) {
                        $scope.cardCadenciaAtivo = true;
                        $scope.checkOption("checkCadenciaAtiva");
                        consultarCadenciasAtivas(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    // Cards de Emails
                    if (!$scope.cardEmailEnviadoAtivo) {
                        $scope.cardEmailEnviadoAtivo = true;
                        $scope.checkOption("checkEmailEnviado");
                        consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "ENV");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardEmailEntregueAtivo) {
                        $scope.cardEmailEntregueAtivo = true;
                        $scope.checkOption("checkEmailEntregue");
                        consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "E");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardEmailBounceAtivo) {
                        $scope.cardEmailBounceAtivo = true;
                        $scope.checkOption("checkEmailBounce");
                        consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "B");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardEmailAbertoAtivo) {
                        $scope.cardEmailAbertoAtivo = true;
                        $scope.checkOption("checkEmailAberto");
                        consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "A");
                        $scope.atividadesSelecionadas += 1;
                    }
                    // Cards de Respostas
                    if (!$scope.cardRespostaPositivaAtivo) {
                        $scope.cardRespostaPositivaAtivo = true;
                        $scope.checkOption("checkRespostaPositiva");
                        consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "P");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardRespostaNeutraAtivo) {
                        $scope.cardRespostaNeutraAtivo = true;
                        $scope.checkOption("checkRespostaNeutra");
                        consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "N");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardRespostaNegativaAtivo) {
                        $scope.cardRespostaNegativaAtivo = true;
                        $scope.checkOption("checkRespostaNegativa");
                        consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "C");
                        $scope.atividadesSelecionadas += 1;
                    }
                    if (!$scope.cardTotalRespostaAtivo) {
                        $scope.cardTotalRespostaAtivo = true;
                        $scope.checkOption("checkTotalResposta");
                        consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "T");
                        $scope.atividadesSelecionadas += 1;
                    }
                    // Card de Janela de Atenção
                    if (!$scope.cardJanelaOportunidadeAtivo) {
                        $scope.cardJanelaOportunidadeAtivo = true;
                        $scope.checkOption("checkJanelaOportunidade");
                        consultarJanelasOportunidades(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "COCA":
                    // Contatos Capturados
                    if ($scope.cardContatosCapturados) {
                        $scope.cardContatosCapturados = !$scope.cardContatosCapturados;
                        $scope.checkOption("checkContatosCapturados");
                        $scope.deletaCard("Contatos Capturados");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardContatosCapturados = !$scope.cardContatosCapturados;
                        $scope.checkOption("checkContatosCapturados");
                        consultarContatosCapturados(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "COAB":
                    // Contatos Abordados (contatos que receberam pelo menos um email)
                    if ($scope.cardContatosAbordados) {
                        $scope.cardContatosAbordados = !$scope.cardContatosAbordados;
                        $scope.checkOption("checkContatosAbordados");
                        $scope.deletaCard("Contatos Abordados");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardContatosAbordados = !$scope.cardContatosAbordados;
                        $scope.checkOption("checkContatosAbordados");
                        consultarContatosAbordados(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "COEA":
                    // Contatos - Emails Abertos (contatos que abriram pelo menos um email)
                    if ($scope.cardContatosEmailsAbertos) {
                        $scope.cardContatosEmailsAbertos =
                            !$scope.cardContatosEmailsAbertos;
                        $scope.checkOption("checkContatosEmailsAbertos");
                        $scope.deletaCard("Contatos - E-mails Abertos");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardContatosEmailsAbertos =
                            !$scope.cardContatosEmailsAbertos;
                        $scope.checkOption("checkContatosEmailsAbertos");
                        consultarContatosEmailsAbertos(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                    break;
                case "COAL":
                    //Confiabilidade Alta
                    if ($scope.cardConfiabilidadeAltaAtivo) {
                        $scope.cardConfiabilidadeAltaAtivo =
                            !$scope.cardConfiabilidadeAltaAtivo;
                        $scope.checkOption("checkConfiabilidadeAlta");
                        $scope.deletaCard("Alta Confiabilidade");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardConfiabilidadeAltaAtivo =
                            !$scope.cardConfiabilidadeAltaAtivo;
                        $scope.checkOption("checkConfiabilidadeAlta");
                        consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "alta");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "COME":
                    //Confiabilidade Média
                    if ($scope.cardConfiabilidadeMediaAtivo) {
                        $scope.cardConfiabilidadeMediaAtivo =
                            !$scope.cardConfiabilidadeMediaAtivo;
                        $scope.checkOption("checkConfiabilidadeMedia");
                        $scope.deletaCard("Média Confiabilidade");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardConfiabilidadeMediaAtivo =
                            !$scope.cardConfiabilidadeMediaAtivo;
                        $scope.checkOption("checkConfiabilidadeMedia");
                        consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "media");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "COBA":
                    //Confiabilidade Baixa
                    if ($scope.cardConfiabilidadeBaixaAtivo) {
                        $scope.cardConfiabilidadeBaixaAtivo =
                            !$scope.cardConfiabilidadeBaixaAtivo;
                        $scope.checkOption("checkConfiabilidadeBaixa");
                        $scope.deletaCard("Baixa Confiabilidade");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardConfiabilidadeBaixaAtivo =
                            !$scope.cardConfiabilidadeBaixaAtivo;
                        $scope.checkOption("checkConfiabilidadeBaixa");
                        consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "baixa");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "COSE":
                    //Confiabilidade Sem
                    if ($scope.cardConfiabilidadeSemAtivo) {
                        $scope.cardConfiabilidadeSemAtivo =
                            !$scope.cardConfiabilidadeSemAtivo;
                        $scope.checkOption("checkConfiabilidadeSem");
                        $scope.deletaCard("Sem Confiabilidade");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardConfiabilidadeSemAtivo =
                            !$scope.cardConfiabilidadeSemAtivo;
                        $scope.checkOption("checkConfiabilidadeSem");
                        consultarConfiabilidade(filtroUsuarios, filtroMeses, filtroCadencias, "sem");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "CAAT":
                    //Cadencias Ativas
                    if ($scope.cardCadenciaAtivo) {
                        $scope.cardCadenciaAtivo = !$scope.cardCadenciaAtivo;
                        $scope.checkOption("checkCadenciaAtiva");
                        $scope.deletaCard("Cadências Ativas");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardCadenciaAtivo = !$scope.cardCadenciaAtivo;
                        $scope.checkOption("checkCadenciaAtiva");
                        consultarCadenciasAtivas(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "EMEN":
                    //E-mails enviados
                    if ($scope.cardEmailEnviadoAtivo) {
                        $scope.cardEmailEnviadoAtivo = !$scope.cardEmailEnviadoAtivo;
                        $scope.checkOption("checkEmailEnviado");
                        $scope.deletaCard("E-mails Enviados");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardEmailEnviadoAtivo = !$scope.cardEmailEnviadoAtivo;
                        $scope.checkOption("checkEmailEnviado");
                        consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "ENV");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "EMET":
                    //E-mails entregues
                    if ($scope.cardEmailEntregueAtivo) {
                        $scope.cardEmailEntregueAtivo = !$scope.cardEmailEntregueAtivo;
                        $scope.checkOption("checkEmailEntregue");
                        $scope.deletaCard("E-mails Entregues");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardEmailEntregueAtivo = !$scope.cardEmailEntregueAtivo;
                        $scope.checkOption("checkEmailEntregue");
                        consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "E");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "EMBO":
                    //E-mails bounces
                    if ($scope.cardEmailBounceAtivo) {
                        $scope.cardEmailBounceAtivo = !$scope.cardEmailBounceAtivo;
                        $scope.checkOption("checkEmailBounce");
                        $scope.deletaCard("E-mails Bounces");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardEmailBounceAtivo = !$scope.cardEmailBounceAtivo;
                        $scope.checkOption("checkEmailBounce");
                        consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "B");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "EMAB":
                    //E-mails abertos
                    if ($scope.cardEmailAbertoAtivo) {
                        $scope.cardEmailAbertoAtivo = !$scope.cardEmailAbertoAtivo;
                        $scope.checkOption("checkEmailAberto");
                        $scope.deletaCard("E-mails Abertos");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardEmailAbertoAtivo = !$scope.cardEmailAbertoAtivo;
                        $scope.checkOption("checkEmailAberto");
                        consultarEnviosEmails(filtroUsuarios, filtroMeses, filtroCadencias, "A");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "REPO":
                    //Respostas positivas
                    if ($scope.cardRespostaPositivaAtivo) {
                        $scope.cardRespostaPositivaAtivo =
                            !$scope.cardRespostaPositivaAtivo;
                        $scope.checkOption("checkRespostaPositiva");
                        $scope.deletaCard("Respostas Positivas");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardRespostaPositivaAtivo =
                            !$scope.cardRespostaPositivaAtivo;
                        $scope.checkOption("checkRespostaPositiva");
                        consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "P");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "RENU":
                    //Respostas neutras
                    if ($scope.cardRespostaNeutraAtivo) {
                        $scope.cardRespostaNeutraAtivo = !$scope.cardRespostaNeutraAtivo;
                        $scope.checkOption("checkRespostaNeutra");
                        $scope.deletaCard("Respostas Neutras");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardRespostaNeutraAtivo = !$scope.cardRespostaNeutraAtivo;
                        $scope.checkOption("checkRespostaNeutra");
                        consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "N");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "RENE":
                    //Respostas negativas
                    if ($scope.cardRespostaNegativaAtivo) {
                        $scope.cardRespostaNegativaAtivo =
                            !$scope.cardRespostaNegativaAtivo;
                        $scope.checkOption("checkRespostaNegativa");
                        $scope.deletaCard("Respostas Negativas");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardRespostaNegativaAtivo =
                            !$scope.cardRespostaNegativaAtivo;
                        $scope.checkOption("checkRespostaNegativa");
                        consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "C");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "TORE":
                    //Total de respostas
                    if ($scope.cardTotalRespostaAtivo) {
                        $scope.cardTotalRespostaAtivo = !$scope.cardTotalRespostaAtivo;
                        $scope.checkOption("checkTotalResposta");
                        $scope.deletaCard("Total de Respostas");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardTotalRespostaAtivo = !$scope.cardTotalRespostaAtivo;
                        $scope.checkOption("checkTotalResposta");
                        consultarRespostas(filtroUsuarios, filtroMeses, filtroCadencias, "T");
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "JAOP":
                    //Janelas oportunidades
                    if ($scope.cardJanelaOportunidadeAtivo) {
                        $scope.cardJanelaOportunidadeAtivo =
                            !$scope.cardJanelaOportunidadeAtivo;
                        $scope.checkOption("checkJanelaOportunidade");
                        $scope.deletaCard("Janela de Atenção");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    else {
                        $scope.cardJanelaOportunidadeAtivo =
                            !$scope.cardJanelaOportunidadeAtivo;
                        $scope.checkOption("checkJanelaOportunidade");
                        consultarJanelasOportunidades(filtroUsuarios, filtroMeses, filtroCadencias);
                        $scope.atividadesSelecionadas += 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                case "TELI":
                    //Tempo de ligação
                    break;
                case "COCO":
                    //Conexão pelo conectar
                    break;
                case "CVCO":
                    //Conversões pelo conectar
                    break;
                case "DESMARCAR":
                    if ($scope.cardContatosCapturados) {
                        $scope.cardContatosCapturados = false;
                        $scope.checkOption("checkContatosCapturados");
                        $scope.deletaCard("Contatos Capturados");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardContatosAbordados) {
                        $scope.cardContatosAbordados = false;
                        $scope.checkOption("checkContatosAbordados");
                        $scope.deletaCard("Contatos Abordados");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardContatosEmailsAbertos) {
                        $scope.cardContatosEmailsAbertos = false;
                        $scope.checkOption("checkContatosEmailsAbertos");
                        $scope.deletaCard("Contatos - E-mails Abertos");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    // Cards de Confiabilidade
                    if ($scope.cardConfiabilidadeAltaAtivo) {
                        $scope.cardConfiabilidadeAltaAtivo = false;
                        $scope.checkOption("checkConfiabilidadeAlta");
                        $scope.deletaCard("Alta Confiabilidade");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardConfiabilidadeMediaAtivo) {
                        $scope.cardConfiabilidadeMediaAtivo = false;
                        $scope.checkOption("checkConfiabilidadeMedia");
                        $scope.deletaCard("Média Confiabilidade");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardConfiabilidadeBaixaAtivo) {
                        $scope.cardConfiabilidadeBaixaAtivo = false;
                        $scope.checkOption("checkConfiabilidadeBaixa");
                        $scope.deletaCard("Baixa Confiabilidade");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardConfiabilidadeSemAtivo) {
                        $scope.cardConfiabilidadeSemAtivo = false;
                        $scope.checkOption("checkConfiabilidadeSem");
                        $scope.deletaCard("Sem Confiabilidade");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    // Card de Cadencias Ativas
                    if ($scope.cardCadenciaAtivo) {
                        $scope.cardCadenciaAtivo = false;
                        $scope.checkOption("checkCadenciaAtiva");
                        $scope.deletaCard("Cadências Ativas");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    // Cards de Emails
                    if ($scope.cardEmailEnviadoAtivo) {
                        $scope.cardEmailEnviadoAtivo = false;
                        $scope.checkOption("checkEmailEnviado");
                        $scope.deletaCard("E-mails Enviados");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardEmailEntregueAtivo) {
                        $scope.cardEmailEntregueAtivo = false;
                        $scope.checkOption("checkEmailEntregue");
                        $scope.deletaCard("E-mails Entregues");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardEmailBounceAtivo) {
                        $scope.cardEmailBounceAtivo = false;
                        $scope.checkOption("checkEmailBounce");
                        $scope.deletaCard("E-mails Bounces");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardEmailAbertoAtivo) {
                        $scope.cardEmailAbertoAtivo = false;
                        $scope.checkOption("checkEmailAberto");
                        $scope.deletaCard("E-mails Abertos");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    // Cards de Respostas
                    if ($scope.cardRespostaPositivaAtivo) {
                        $scope.cardRespostaPositivaAtivo = false;
                        $scope.checkOption("checkRespostaPositiva");
                        $scope.deletaCard("Respostas Positivas");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardRespostaNeutraAtivo) {
                        $scope.cardRespostaNeutraAtivo = false;
                        $scope.checkOption("checkRespostaNeutra");
                        $scope.deletaCard("Respostas Neutras");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardRespostaNegativaAtivo) {
                        $scope.cardRespostaNegativaAtivo = false;
                        $scope.checkOption("checkRespostaNegativa");
                        $scope.deletaCard("Respostas Negativas");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    if ($scope.cardTotalRespostaAtivo) {
                        $scope.cardTotalRespostaAtivo = false;
                        $scope.checkOption("checkTotalResposta");
                        $scope.deletaCard("Total de Respostas");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    // Card de Janela de Atenção
                    if ($scope.cardJanelaOportunidadeAtivo) {
                        $scope.cardJanelaOportunidadeAtivo = false;
                        $scope.checkOption("checkJanelaOportunidade");
                        $scope.deletaCard("Janela de Atenção");
                        $scope.atividadesSelecionadas -= 1;
                    }
                    $scope.ajustarPopupNovoCard();
                    if (salvarCard)
                        $scope.salvarCardsTela();
                    break;
                default:
                    break;
            }
            // $scope.togglePopUp();
        };
        $scope.fechaCard = function (pValor) {
            $scope.adicionaCard(pValor, true);
            if (pValor == $scope.card.code) {
                $scope.abrirGrafico = false;
                $scope.nomeGrafico = "";
                $scope.idGraficoAberto = 0;
                var tituloGraficoAcumulativo = document.getElementById("idTituloGraficoAcumulativo");
                $(tituloGraficoAcumulativo).removeClass("bold");
                $scope.textoGraficoAcumulativo = false;
                var tituloGraficoComparativo = document.getElementById("idTituloGraficoComparativo");
                $(tituloGraficoComparativo).removeClass("bold");
                $scope.textoGraficoComparativo = false;
            }
        };
        $scope.deletaCard = function (pCampo) {
            $scope.cards.splice(buscarElemArray(pCampo, $scope.cards), 1);
            // $scope.togglePopUp();
        };
        function buscarElemArray(pCampo, pArray) {
            for (var i = 0; i < pArray.length; i++) {
                if (pArray[i].titulo === pCampo) {
                    return i;
                }
            }
        }
        $scope.buscarCard = function (idBusca, idFiltro) {
            var input, filter, div, td, tr, i;
            input = document.getElementById(idBusca);
            filter = input.value.toUpperCase();
            div = document.getElementById(idFiltro);
            tr = div.getElementsByTagName("tr");
            td = div.getElementsByTagName("td");
            for (i = 0; i < td.length; i++) {
                var txtValue = td[i].textContent || td[i].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    td[i].style.display = "";
                    tr[i].style.display = "";
                }
                else {
                    td[i].style.display = "none";
                    tr[i].style.display = "none";
                }
            }
        };
        $(document).mousedown(function (event) {
            var clickForaCard = popupsCard.contains(event.target);
            var clickForaFiltro = popupsFiltro.contains(event.target);
            var clickForaRanking = popupsRanking.contains(event.target);
            if (!clickForaCard && !clickForaFiltro && !clickForaRanking) {
                $scope.togglePopUp("todos");
                $scope.$apply();
            }
        });
        $scope.togglePopUp = function (popup) {
            if (popup == "todos") {
                $scope.mostraAddCard = false;
                $scope.mostraFiltro = false;
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraAtividadesRanking = false;
            }
            else if (popup == "card") {
                $scope.mostraAddCard = !$scope.mostraAddCard;
                // $scope.ajustarPopups('#addCard', 0, -70);
                $scope.ajustarPopupNovoCard();
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraAtividadesRanking = false;
            }
            else if (popup == "filtro") {
                $scope.mostraFiltro = !$scope.mostraFiltro;
                if ($scope.mostraFiltro == false) {
                    $scope.mostraFiltroUsuarios = false;
                    $scope.mostraFiltroCadencias = false;
                    $scope.mostraFiltroMes = false;
                }
                $scope.mostraAddCard = false;
                $scope.mostraAtividadesRanking = false;
            }
            else if (popup == "usuarios") {
                $scope.mostraFiltroUsuarios = !$scope.mostraFiltroUsuarios;
                $scope.ajustarPopups("#filtro-usuario", 0, 0);
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraAtividadesRanking = false;
            }
            else if (popup == "cadencias") {
                $scope.mostraFiltroCadencias = !$scope.mostraFiltroCadencias;
                $scope.ajustarPopups("#filtro-cadencia", 0, 0);
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraAtividadesRanking = false;
            }
            else if (popup == "mes") {
                $scope.mostraFiltroMes = !$scope.mostraFiltroMes;
                $scope.ajustarPopups("#filtro-mes", 0, 0);
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraAtividadesRanking = false;
            }
            else if (popup == "ranking") {
                $scope.mostraAtividadesRanking = !$scope.mostraAtividadesRanking;
                $scope.ajustarPopups("#abreRanking", 0, 0);
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
            }
        };
        $scope.colapseOrExpandMenu = function (menu) {
            if (menu == "analise") {
                $scope.menuNavAberto = !$scope.menuNavAberto;
                if ($scope.menuNavAberto) {
                    $("#expandOrCollapseSideMenu").addClass("collapseMenuNav");
                    $("#menuNav").removeClass("menuNavCol");
                    $("#expandOrCollapseSideMenu").removeClass("expandMenuNav");
                    setTimeout(function () {
                        $("#tituloMenuLateral").removeClass("escondeMenuLateral");
                        $("#conteudoMenuLateral").removeClass("escondeMenuLateral");
                    }, 300);
                }
                else {
                    $("#expandOrCollapseSideMenu").removeClass("collapseMenuNav");
                    $("#menuNav").addClass("menuNavCol");
                    $("#tituloMenuLateral").addClass("escondeMenuLateral");
                    $("#conteudoMenuLateral").addClass("escondeMenuLateral");
                    $("#expandOrCollapseSideMenu").addClass("expandMenuNav");
                }
            }
            if (menu == "ranking") {
                $scope.rankingMenuAberto = !$scope.rankingMenuAberto;
                if ($scope.rankingMenuAberto) {
                    $("#containerMenuRanking").addClass("col-md-12");
                    $("#expandOrCollapseSideRanking").addClass("collapseRankingNav");
                    $("#rankingNav").removeClass("rankingNavCol");
                    $("#expandOrCollapseSideRanking").removeClass("expandRankingNav");
                    setTimeout(function () {
                        $("#containerMenuRanking").removeClass("escondeMenuLateral");
                        $scope.ajustarPopups("#filtro-usuario", 0, 0);
                        $scope.ajustarPopups("#filtro-cadencia", 0, 0);
                        $scope.ajustarPopups("#filtro-mes", 0, 0);
                        // $("#conteudoMenuLateral").removeClass("escondeMenuLateral");
                    }, 300);
                }
                else {
                    $("#containerMenuRanking").removeClass("col-md-12");
                    $("#expandOrCollapseSideRanking").removeClass("collapseRankingNav");
                    $("#rankingNav").addClass("rankingNavCol");
                    $("#containerMenuRanking").addClass("escondeMenuLateral");
                    // $("#conteudoMenuLateral").addClass("escondeMenuLateral");
                    $("#expandOrCollapseSideRanking").addClass("expandRankingNav");
                    setTimeout(function () {
                        $scope.ajustarPopups("#filtro-usuario", 0, 0);
                        $scope.ajustarPopups("#filtro-cadencia", 0, 0);
                        $scope.ajustarPopups("#filtro-mes", 0, 0);
                    }, 300);
                }
            }
        };
        $scope.checkOption = function (checked) {
            var obj;
            obj = document.getElementById(checked);
            if (!$(obj).hasClass("option-checked")) {
                $(obj).addClass("option-checked");
            }
            else {
                $(obj).removeClass("option-checked");
            }
        };
        function animateValue(id, start, end, duration) {
            var range = start - end;
            var current = 0;
            var increment = end > 100 ? 1 : 1;
            var stepTime = Math.abs(Math.floor(duration / range));
            var obj = document.getElementById(id);
            var timer = setInterval(function () {
                current += increment;
                obj.innerHTML = current;
                if (current >= end) {
                    obj.innerHTML = end;
                    clearInterval(timer);
                }
            }, stepTime);
        }
        function checkarPosicao(elem, offsetX, offsetY) {
            var sides = {
                top: false,
                right: false,
                bottom: false,
                left: false,
                any: false,
                all: false,
            };
            if (window.innerWidth < 1600 ||
                document.documentElement.clientHeight < 1600)
                sides.top = elem.top > 530;
            else
                sides.top = elem.top > 650;
            // sides.top = elem.top > (window.innerHeight || document.documentElement.clientHeight);
            sides.left =
                elem.left + offsetX >
                    (window.innerWidth || document.documentElement.clientWidth);
            sides.bottom =
                elem.bottom >
                    (window.innerHeight || document.documentElement.clientHeight);
            sides.right =
                elem.right + offsetX >
                    (window.innerWidth || document.documentElement.clientWidth);
            sides.any = sides.top || sides.left || sides.bottom || sides.right;
            sides.all = sides.top && sides.left && sides.bottom && sides.right;
            return sides;
        }
        $scope.ajustarPopups = function (popup, offsetX, offsetY) {
            var obj = document.querySelector(popup);
            $(obj).removeClass("reposicionar");
            $(obj).removeClass("reposicionarNovoCardParaCima");
            $(obj).removeClass("reposicionarNovoCardParaEsquerda");
            $(obj).removeClass("reposicionarNovoCardParaCimaEsquerda");
            setTimeout(function () {
                var boundingLista;
                boundingLista = obj.getBoundingClientRect();
                var sides = {
                    top: false,
                    right: false,
                    bottom: false,
                    left: false,
                    any: false,
                    all: false,
                };
                sides = checkarPosicao(boundingLista, offsetX, offsetY);
                if (($scope.mostraFiltroUsuarios ||
                    $scope.mostraFiltroCadencias ||
                    $scope.mostraFiltroMes) &&
                    sides.any) {
                    $(obj).addClass("reposicionar");
                }
                // if (($scope.mostraAddCard) && sides.bottom) {
                //     $(obj).addClass('reposicionarNovoCardParaCima');
                // }
                // else if ($scope.mostraAddCard && sides.any) {
                //     $(obj).addClass('reposicionarCard');
                // }
            }, 100);
        };
        $scope.ajustarPopupNovoCard = function () {
            var obj = document.querySelector("#addCard");
            $(obj).removeClass("reposicionarNovoCardParaCima");
            $(obj).removeClass("reposicionarNovoCardParaEsquerda");
            $(obj).removeClass("reposicionarNovoCardParaCimaEsquerda");
            if ($scope.atividadesSelecionadas >= 0) {
                setTimeout(function () {
                    var boundingLista;
                    boundingLista = obj.getBoundingClientRect();
                    var sides = {
                        top: false,
                        right: false,
                        bottom: false,
                        left: false,
                        any: false,
                        all: false,
                    };
                    sides = checkarPosicao(boundingLista, 280, 0);
                    // if (($scope.mostraAddCard) && sides.bottom) {
                    //     $(obj).addClass('reposicionarNovoCard');
                    // }
                    if ($scope.mostraAddCard && sides.right && sides.top) {
                        $(obj).addClass("reposicionarNovoCardParaCimaEsquerda");
                    }
                    else if ($scope.mostraAddCard && sides.right) {
                        $(obj).addClass("reposicionarNovoCardParaEsquerda");
                    }
                    else if ($scope.mostraAddCard && sides.top) {
                        $(obj).addClass("reposicionarNovoCardParaCima");
                    }
                });
            }
        };
        $scope.selecionaRanking = function (atividade) {
            $scope.rankingLoader = true;
            $scope.atvRankingSelecionado = atividade;
            for (var i = 0; i < $scope.listaAtividadesRanking.length; i++) {
                if ($scope.listaAtividadesRanking[i])
                    $scope.checkOption($scope.listaCheckAtividadesRankings[i]);
                $scope.listaAtividadesRanking[i] = false;
            }
            $scope.dadosRanking = [];
            for (var i = 0; i < $scope.usuariosTime.length; i++) {
                $scope.dadosRanking.push({
                    id: $scope.usuariosTime[i].id,
                    nome: $scope.usuariosTime[i].nome,
                    sobrenome: $scope.usuariosTime[i].sobrenome,
                    objectIdLogin: $scope.usuariosTime[i].objectIdLogin,
                    dadosMesAtual: 0,
                    dadosMesAnterior: 0,
                    posicao: 0,
                    percMudanca: 0,
                    color: "",
                    maiorQueMil: false,
                });
            }
            switch (atividade) {
                case "COCA":
                    // Contatos Capturados
                    $scope.ativRankingEscolhido = "COCA";
                    $scope.listaAtividadesRanking[0] = true;
                    $scope.atividadeRanking = "Contatos capturados";
                    $scope.checkOption("checkContatosCapturadosRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarContatosCapturados",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        for (var i = 0; i < listaContatos.length; i++) {
                            if (new Date(listaContatos[i].dataInclusao) >=
                                new Date(new Date($scope.dataRanking))) {
                                for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                    if (listaContatos[i].objectIdLogin ==
                                        $scope.dadosRanking[j].objectIdLogin) {
                                        $scope.dadosRanking[j].dadosMesAtual++;
                                        j = $scope.dadosRanking.length;
                                    }
                                }
                            }
                            else {
                                for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                    if (listaContatos[i].objectIdLogin ==
                                        $scope.dadosRanking[j].objectIdLogin) {
                                        $scope.dadosRanking[j].dadosMesAnterior++;
                                        j = $scope.dadosRanking.length;
                                    }
                                }
                            }
                        }
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "COAB":
                    // Contatos Abordados
                    $scope.ativRankingEscolhido = "COAB";
                    $scope.listaAtividadesRanking[1] = true;
                    $scope.atividadeRanking = "Contatos abordados";
                    $scope.checkOption("checkContatosAbordadosRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarContatosAbordados",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        var dataRankingAtual = new Date($scope.dataRanking);
                        var dataRankingAnterior = new Date($scope.dataRanking);
                        dataRankingAnterior.setMonth(dataRankingAnterior.getMonth() - 1);
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        var listaDisparosEmailsAgrupadosPorContatos = agrupaArrayPorChave(listaContatos, "objectIdContato");
                        // Verifica na lista de contatos retornada do backend se a data e menor ou maior que o comeco do mes
                        // e incrementa o dado do mes atual ou passado de acordo com a data que esse contato pertence
                        for (var contato in listaDisparosEmailsAgrupadosPorContatos) {
                            var contMesAtual = false;
                            var contMesAnterior = false;
                            for (var i = 0; i < listaDisparosEmailsAgrupadosPorContatos[contato].length; i++) {
                                if (new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                    .dataEnvio) >= dataRankingAtual &&
                                    !contMesAtual) {
                                    for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                        if (listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                            .objectIdLogin == $scope.dadosRanking[j].objectIdLogin) {
                                            $scope.dadosRanking[j].dadosMesAtual++;
                                            j = $scope.dadosRanking.length;
                                            contMesAtual = true;
                                        }
                                    }
                                }
                                else {
                                    if (new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                        .dataEnvio) > dataRankingAnterior &&
                                        new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                            .dataEnvio) < dataRankingAtual &&
                                        !contMesAnterior) {
                                        for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                            if (listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                                .objectIdLogin == $scope.dadosRanking[j].objectIdLogin) {
                                                $scope.dadosRanking[j].dadosMesAnterior++;
                                                j = $scope.dadosRanking.length;
                                                contMesAnterior = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "COEA":
                    $scope.ativRankingEscolhido = "COEA";
                    $scope.listaAtividadesRanking[2] = true;
                    $scope.atividadeRanking = "Contatos - e-mails abertos";
                    $scope.checkOption("checkContatosEmailsAbertosRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarContatosEmailsAbertos",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        var dataRankingAtual = new Date($scope.dataRanking);
                        var dataRankingAnterior = new Date($scope.dataRanking);
                        dataRankingAnterior.setMonth(dataRankingAnterior.getMonth() - 1);
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        var listaDisparosEmailsAgrupadosPorContatos = agrupaArrayPorChave(listaContatos, "objectIdContato");
                        for (var contato in listaDisparosEmailsAgrupadosPorContatos) {
                            var contMesAtual = false;
                            var contMesAnterior = false;
                            for (var i = 0; i < listaDisparosEmailsAgrupadosPorContatos[contato].length; i++) {
                                if (new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                    .dataAbertura) >= dataRankingAtual &&
                                    !contMesAtual) {
                                    for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                        if (listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                            .objectIdLogin == $scope.dadosRanking[j].objectIdLogin) {
                                            $scope.dadosRanking[j].dadosMesAtual++;
                                            j = $scope.dadosRanking.length;
                                            contMesAtual = true;
                                        }
                                    }
                                }
                                else {
                                    if (new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                        .dataAbertura) > dataRankingAnterior &&
                                        new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                            .dataAbertura) < dataRankingAtual &&
                                        !contMesAnterior) {
                                        for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                            if (listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                                .objectIdLogin == $scope.dadosRanking[j].objectIdLogin) {
                                                $scope.dadosRanking[j].dadosMesAnterior++;
                                                j = $scope.dadosRanking.length;
                                                contMesAnterior = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "COAL":
                    // Confiabilidade Alta
                    $scope.ativRankingEscolhido = "COAL";
                    $scope.listaAtividadesRanking[3] = true;
                    $scope.atividadeRanking = "Alta confiabilidade";
                    $scope.checkOption("checkConfiabilidadeAltaRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarConfiabilidade",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pGrauConfiabilidade: "alta",
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraConfiabilidadeRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "COME":
                    // Confiabilidade Média
                    $scope.ativRankingEscolhido = "COME";
                    $scope.listaAtividadesRanking[4] = true;
                    $scope.atividadeRanking = "Média confiabilidade";
                    $scope.checkOption("checkConfiabilidadeMediaRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarConfiabilidade",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pGrauConfiabilidade: "media",
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraConfiabilidadeRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "COBA":
                    // Confiabilidade Baixa
                    $scope.ativRankingEscolhido = "COBA";
                    $scope.listaAtividadesRanking[5] = true;
                    $scope.atividadeRanking = "Baixa confiabilidade";
                    $scope.checkOption("checkConfiabilidadeBaixaRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarConfiabilidade",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pGrauConfiabilidade: "baixa",
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraConfiabilidadeRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "COSE":
                    // Sem Confiabilidade
                    $scope.ativRankingEscolhido = "COSE";
                    $scope.listaAtividadesRanking[6] = true;
                    $scope.atividadeRanking = "Sem confiabilidade";
                    $scope.checkOption("checkConfiabilidadeSemRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarConfiabilidade",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pGrauConfiabilidade: "sem",
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraConfiabilidadeRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "CAAT":
                    //Cadencias Ativas
                    $scope.ativRankingEscolhido = "CAAT";
                    $scope.listaAtividadesRanking[7] = true;
                    $scope.atividadeRanking = "Cadências ativas";
                    $scope.checkOption("checkCadenciaAtivaRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarCadenciasAtivas",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            pObjectIdLogin: $scope.timeRanking,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        // Add a lista de cadencias ativas nos dados do Ranking
                        for (var i = 0; i < listaContatos.length; i++) {
                            for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                if (listaContatos[i].objectIdLogin ==
                                    $scope.dadosRanking[j].objectIdLogin) {
                                    $scope.dadosRanking[j].dadosMesAtual++;
                                    j = $scope.dadosRanking.length;
                                }
                            }
                        }
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "EMEN":
                    //E-mails enviados
                    $scope.ativRankingEscolhido = "EMEN";
                    $scope.listaAtividadesRanking[8] = true;
                    $scope.atividadeRanking = "E-mails enviados";
                    $scope.checkOption("checkEmailEnviadoRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarEnviosEmails",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                            pStatus: "ENV",
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraEmailsRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "EMET":
                    //E-mails entregues
                    $scope.ativRankingEscolhido = "EMET";
                    $scope.listaAtividadesRanking[9] = true;
                    $scope.atividadeRanking = "E-mails entregues";
                    $scope.checkOption("checkEmailEntregueRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarEnviosEmails",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                            pStatus: "E",
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraEmailsRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "EMBO":
                    //E-mails bounces
                    $scope.ativRankingEscolhido = "EMBO";
                    $scope.listaAtividadesRanking[10] = true;
                    $scope.atividadeRanking = "E-mails bounces";
                    $scope.checkOption("checkEmailBounceRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarEnviosEmails",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                            pStatus: "B",
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraEmailsRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "EMAB":
                    //E-mails abertos
                    $scope.ativRankingEscolhido = "EMAB";
                    $scope.listaAtividadesRanking[11] = true;
                    $scope.atividadeRanking = "E-mails abertos";
                    $scope.checkOption("checkEmailAbertoRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarEnviosEmails",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                            pStatus: "A",
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        // Verifica na lista de contatos retornada do backend se a data e menor ou maior que o comeco do mes
                        // e incrementa o dado do mes atual ou passado de acordo com a data que esse contato pertence
                        for (var i = 0; i < listaContatos.length; i++) {
                            if (new Date(listaContatos[i].dataAbertura) >=
                                new Date(new Date($scope.dataRanking))) {
                                for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                    if (listaContatos[i].objectIdLogin ==
                                        $scope.dadosRanking[j].objectIdLogin) {
                                        $scope.dadosRanking[j].dadosMesAtual++;
                                        j = $scope.dadosRanking.length;
                                    }
                                }
                            }
                            else {
                                for (var j = 0; j < $scope.dadosRanking.length; j++) {
                                    if (listaContatos[i].objectIdLogin ==
                                        $scope.dadosRanking[j].objectIdLogin) {
                                        $scope.dadosRanking[j].dadosMesAnterior++;
                                        j = $scope.dadosRanking.length;
                                    }
                                }
                            }
                        }
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "REPO":
                    //Respostas positivas
                    $scope.ativRankingEscolhido = "REPO";
                    $scope.listaAtividadesRanking[12] = true;
                    $scope.atividadeRanking = "Respostas positivas";
                    $scope.checkOption("checkRespostaPositivaRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarRespostas",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                            pStatus: "P",
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraRespostasRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "RENU":
                    //Respostas neutras
                    $scope.ativRankingEscolhido = "RENU";
                    $scope.listaAtividadesRanking[13] = true;
                    $scope.atividadeRanking = "Respostas neutras";
                    $scope.checkOption("checkRespostaNeutraRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarRespostas",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                            pStatus: "C",
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraRespostasRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "RENE":
                    //Respostas negativas
                    $scope.ativRankingEscolhido = "RENE";
                    $scope.listaAtividadesRanking[14] = true;
                    $scope.atividadeRanking = "Respostas negativas";
                    $scope.checkOption("checkRespostaNegativaRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarRespostas",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                            pStatus: "N",
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraRespostasRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "TORE":
                    //Total de respostas
                    $scope.ativRankingEscolhido = "TORE";
                    $scope.listaAtividadesRanking[15] = true;
                    $scope.atividadeRanking = "Total de respostas";
                    $scope.checkOption("checkTotalRespostaRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarRespostas",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                            pStatus: "T",
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        filtraRespostasRanking(listaContatos);
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                case "JAOP":
                    //Janelas oportunidades
                    $scope.ativRankingEscolhido = "JAOP";
                    $scope.listaAtividadesRanking[16] = true;
                    $scope.atividadeRanking = "Janela de atenção";
                    $scope.checkOption("checkJanelaOportunidadeRanking");
                    $http({
                        method: "POST",
                        url: "/relatorio/consultarJanelasOportunidades",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                        data: {
                            dateFilter: $scope.dataRanking,
                            pObjectIdLogin: $scope.timeRanking,
                            pObjectIdDisparos: filtroCadencias,
                        },
                    }).then(function (response) {
                        var listaContatos = response.data;
                        $scope.rankingMesAtual = 0;
                        $scope.rankingMesAnterior = 0;
                        var dataRankingAtual = new Date($scope.dataRanking);
                        var dataRankingAnterior = new Date($scope.dataRanking);
                        dataRankingAnterior.setMonth(dataRankingAnterior.getMonth() - 1);
                        // Verifica na lista de E-mails retornada do backend se a data e menor ou maior que o comeco do mes
                        // e incrementa o dado do mes atual ou passado de acordo com a data que esse contato pertence
                        for (var i = 0; i < listaContatos.length; i++) {
                            contMesAtual = false;
                            contMesAnterior = false;
                            for (var j = 0; j < listaContatos[i].oportunidadeConexao.dataEntrada.length; j++) {
                                if (new Date(listaContatos[i].oportunidadeConexao.dataEntrada[j]) >= dataRankingAtual &&
                                    !contMesAtual) {
                                    for (var k = 0; k < $scope.dadosRanking.length; k++) {
                                        if (listaContatos[i].objectIdLogin ==
                                            $scope.dadosRanking[k].objectIdLogin) {
                                            $scope.dadosRanking[k].dadosMesAtual++;
                                            k = $scope.dadosRanking.length;
                                            contMesAtual = true;
                                        }
                                    }
                                }
                                else {
                                    if (new Date(listaContatos[i].oportunidadeConexao.dataEntrada[j]) > dataRankingAnterior &&
                                        new Date(listaContatos[i].oportunidadeConexao.dataEntrada[j]) < dataRankingAtual &&
                                        !contMesAnterior) {
                                        for (var k = 0; k < $scope.dadosRanking.length; k++) {
                                            if (listaContatos[i].objectIdLogin ==
                                                $scope.dadosRanking[k].objectIdLogin) {
                                                $scope.dadosRanking[k].dadosMesAnterior++;
                                                k = $scope.dadosRanking.length;
                                                contMesAnterior = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ordenaDadosRanking();
                        calculaPorcentagemDadosRanking();
                        $scope.controleRankingShow = "todos";
                        $scope.textoRankingShow = "mais";
                        $scope.selecionaRankingShow();
                    });
                    break;
                // case 'TELI':
                //     //Tempo de ligação
                //     break;
                // case 'COCO':
                //     //Conexão pelo conectar
                //     break;
                // case 'CVCO':
                //     //Conversões pelo conectar
                //     break;
                default:
                    break;
            }
        };
        $scope.selecionaRankingShow = function () {
            $scope.dadosRankingShow = [];
            // Para voltar com o btn "ver mais usuarios":
            // 1. Descomentar a div com o id "containerMenuRanking" no relatorio.html
            // 2. Descomentar a linha abaixo e comentar a segunda abaixo
            // if ($scope.controleRankingShow === 'tres') {
            if (true) {
                $scope.controleRankingShow = "todos";
                $scope.textoRankingShow = "menos";
                $scope.rankingLoader = false;
                $scope.dadosRankingShow = $scope.dadosRanking;
            }
            else {
                $scope.controleRankingShow = "tres";
                $scope.textoRankingShow = "mais";
                $scope.rankingLoader = false;
                $scope.dadosRankingShow = $scope.dadosRanking.slice(0, 3);
            }
        };
        function ordenaDadosRanking() {
            // Ordena os dados recebidos para que o ranking possa ser feito e quem capturou mais contatos fique em primeiro
            $scope.dadosRanking.sort(function (x, y) {
                if (x.dadosMesAtual == y.dadosMesAtual) {
                    if (x.dadosMesAnterior == y.dadosMesAnterior) {
                        // return x.nome.charCodeAt() - y.nome.charCodeAt();
                        return ("" + x.nome + x.sobrenome).localeCompare(y.nome + y.sobrenome);
                    }
                    else {
                        return x.dadosMesAnterior - y.dadosMesAnterior;
                    }
                }
                return y.dadosMesAtual - x.dadosMesAtual;
            });
        }
        function ordenaFiltroUsuarios() {
            // Ordena os usuarios para que apareça em ordem alfabetica e com os ativos separados dos inativos
            $scope.usuariosTime.sort(function (x, y) {
                // if (x.status != 'I' && y.status != 'I') {}
                return ("" + x.nome + x.sobrenome).localeCompare(y.nome + y.sobrenome);
            });
        }
        function ordenaFiltroCadencia() {
            // Ordena as cadências em ordem alfabética
            $scope.cadenciasTime.sort(function (x, y) {
                return ("" + x.nomeCadencia).localeCompare("" + y.nomeCadencia);
            });
        }
        function calculaPorcentagemDadosRanking() {
            // Faz a contagem de dados por mes e calcula a porcentagem de um mes para outro, alem disso, faz
            // a mudanca da cor do quadrado que mostra a porcentagem de acordo com o valor
            for (var i = 0; i < $scope.dadosRanking.length; i++) {
                $scope.dadosRanking[i].posicao = i + 1;
                $scope.dadosRanking[i].percMudanca = 0;
                if ($scope.dadosRanking[i].dadosMesAtual !== 0 &&
                    $scope.dadosRanking[i].dadosMesAnterior)
                    $scope.dadosRanking[i].percMudanca =
                        (($scope.dadosRanking[i].dadosMesAtual -
                            $scope.dadosRanking[i].dadosMesAnterior) /
                            $scope.dadosRanking[i].dadosMesAnterior) *
                            100;
                if ($scope.dadosRanking[i].percMudanca >= 1000) {
                    $scope.dadosRanking[i].maiorQueMil = true;
                }
                if ($scope.dadosRanking[i].percMudanca > 0) {
                    $scope.dadosRanking[i].color = { "background-color": "#75c18d" };
                }
                else if ($scope.dadosRanking[i].percMudanca == 0) {
                    $scope.dadosRanking[i].color = { "background-color": "#a8a8a8" };
                }
                else {
                    $scope.dadosRanking[i].color = { "background-color": "#ff5050" };
                }
                // Regras especiais para o Bounce já que ter menos bounce que o período anterior é algo bom
                if ($scope.ativRankingEscolhido === "EMBO") {
                    if ($scope.dadosRanking[i].percMudanca > 0) {
                        $scope.dadosRanking[i].color = { "background-color": "#ff5050" };
                    }
                    if ($scope.dadosRanking[i].percMudanca < 0) {
                        $scope.dadosRanking[i].color = { "background-color": "#75c18d" };
                    }
                }
            }
        }
        function filtraConfiabilidadeRanking(listaContatos) {
            // Verifica na lista de contatos retornada do backend se a data e menor ou maior que o comeco do mes
            // e incrementa o dado do mes atual ou passado de acordo com a data que esse contato pertence
            for (var i = 0; i < listaContatos.length; i++) {
                if (new Date(listaContatos[i].dataInclusao) >=
                    new Date(new Date($scope.dataRanking))) {
                    for (var j = 0; j < $scope.dadosRanking.length; j++) {
                        if (listaContatos[i].objectIdLogin ==
                            $scope.dadosRanking[j].objectIdLogin) {
                            $scope.dadosRanking[j].dadosMesAtual++;
                            j = $scope.dadosRanking.length;
                        }
                    }
                }
                else {
                    for (var j = 0; j < $scope.dadosRanking.length; j++) {
                        if (listaContatos[i].objectIdLogin ==
                            $scope.dadosRanking[j].objectIdLogin) {
                            $scope.dadosRanking[j].dadosMesAnterior++;
                            j = $scope.dadosRanking.length;
                        }
                    }
                }
            }
        }
        function filtraEmailsRanking(listaContatos) {
            // Verifica na lista de E-mails retornada do backend se a data e menor ou maior que o comeco do mes
            // e incrementa o dado do mes atual ou passado de acordo com a data que esse contato pertence
            for (var i = 0; i < listaContatos.length; i++) {
                if (new Date(listaContatos[i].dataEnvio) >=
                    new Date(new Date($scope.dataRanking))) {
                    for (var j = 0; j < $scope.dadosRanking.length; j++) {
                        if (listaContatos[i].objectIdLogin ==
                            $scope.dadosRanking[j].objectIdLogin) {
                            $scope.dadosRanking[j].dadosMesAtual++;
                            j = $scope.dadosRanking.length;
                        }
                    }
                }
                else {
                    for (var j = 0; j < $scope.dadosRanking.length; j++) {
                        if (listaContatos[i].objectIdLogin ==
                            $scope.dadosRanking[j].objectIdLogin) {
                            $scope.dadosRanking[j].dadosMesAnterior++;
                            j = $scope.dadosRanking.length;
                        }
                    }
                }
            }
        }
        function filtraRespostasRanking(listaContatos) {
            // Verifica na lista de E-mails retornada do backend se a data e menor ou maior que o comeco do mes
            // e incrementa o dado do mes atual ou passado de acordo com a data que esse contato pertence
            for (var i = 0; i < listaContatos.length; i++) {
                if (new Date(listaContatos[i].dataResposta) >=
                    new Date(new Date($scope.dataRanking))) {
                    for (var j = 0; j < $scope.dadosRanking.length; j++) {
                        if (listaContatos[i].objectIdLogin ==
                            $scope.dadosRanking[j].objectIdLogin) {
                            $scope.dadosRanking[j].dadosMesAtual++;
                            j = $scope.dadosRanking.length;
                        }
                    }
                }
                else {
                    for (var j = 0; j < $scope.dadosRanking.length; j++) {
                        if (listaContatos[i].objectIdLogin ==
                            $scope.dadosRanking[j].objectIdLogin) {
                            $scope.dadosRanking[j].dadosMesAnterior++;
                            j = $scope.dadosRanking.length;
                        }
                    }
                }
            }
        }
        function consultarContatosCapturados(pFiltros, pData, pCadencias) {
            $scope.tituloDoCard = "Contatos Capturados";
            $scope.code = "COCA";
            $scope.order = { order: 1 };
            var id = $scope.tituloDoCard.trim();
            var cardEstaNaTela = false;
            for (var i = 0; i < $scope.cards.length; i++) {
                if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                    $scope.cards[i].id = id;
                    $scope.cards[i].titulo = $scope.tituloDoCard;
                    $scope.cards[i].order = $scope.order;
                    $scope.cards[i].code = $scope.code;
                    $scope.cards[i].loader = true;
                    cardEstaNaTela = true;
                    break;
                }
            }
            if (!cardEstaNaTela) {
                $scope.cards.push({
                    id: id,
                    titulo: $scope.tituloDoCard,
                    order: $scope.order,
                    code: $scope.code,
                    loader: true,
                });
            }
            $http({
                method: "POST",
                url: "/relatorio/consultarContatosCapturados",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    listaTotalUsuarios: $scope.usuariosTime,
                    dateFilter: pData[0].data,
                    pObjectIdLogin: pFiltros,
                    pObjectIdDisparos: pCadencias,
                },
            }).then(function (response) {
                $scope.tituloDoCard = "Contatos Capturados";
                $scope.code = "COCA";
                $scope.order = { order: 1 };
                var id = $scope.tituloDoCard.trim();
                var cardEstaNaTela = false;
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = true;
                        cardEstaNaTela = true;
                        break;
                    }
                }
                $scope.valorDoCard = 0;
                $scope.valorAnteriorDoCard = 0;
                $scope.percMudanca = 0;
                $scope.maiorQueMil = false;
                var arrayDadosMesAtual = [];
                var arrayDadosMesAnterior = [];
                for (var i = 0; i < response.data.length; i++) {
                    if (new Date(response.data[i].dataInclusao) >= new Date(pData[0].data)) {
                        var aux = 0;
                        aux = new Date(response.data[i].dataInclusao).getDate();
                        if (aux < 10)
                            aux = "0" + aux;
                        else
                            aux = aux.toString();
                        arrayDadosMesAtual.push(aux);
                        $scope.valorDoCard++;
                    }
                    else {
                        var aux = 0;
                        aux = new Date(response.data[i].dataInclusao).getDate();
                        if (aux < 10)
                            aux = "0" + aux;
                        else
                            aux = aux.toString();
                        arrayDadosMesAnterior.push(aux);
                        $scope.valorAnteriorDoCard++;
                    }
                }
                $scope.listaContatosCapturados = arrayDadosMesAtual;
                $scope.listaContatosCapturadosMesAnterior = arrayDadosMesAnterior;
                if ($scope.valorDoCard !== 0 && $scope.valorAnteriorDoCard !== 0)
                    $scope.percMudanca =
                        (($scope.valorDoCard - $scope.valorAnteriorDoCard) /
                            $scope.valorAnteriorDoCard) *
                            100;
                if ($scope.percMudanca > 0) {
                    $scope.color = { "background-color": "#75c18d" };
                }
                else if ($scope.percMudanca == 0) {
                    $scope.color = { "background-color": "#a8a8a8" };
                }
                else {
                    $scope.color = { "background-color": "#ff5050" };
                }
                if ($scope.percMudanca >= 1000) {
                    $scope.maiorQueMil = true;
                }
                for (var i = 0; i < $scope.cards.length; i++) {
                    // if ($scope.cards[i].titulo === 'Contatos Capturados') {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].valor = $scope.valorDoCard;
                        $scope.cards[i].valorAnterior = $scope.valorAnteriorDoCard;
                        $scope.cards[i].percMudanca = $scope.percMudanca;
                        $scope.cards[i].maiorQueMil = $scope.maiorQueMil;
                        $scope.cards[i].color = $scope.color;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = false;
                        break;
                    }
                }
                if ($scope.nomeGrafico == $scope.tituloDoCard) {
                    if ($scope.idGraficoAberto === 1)
                        $scope.abreGraficoAcumulativo();
                    if ($scope.idGraficoAberto === 2)
                        $scope.abreGraficoComparativo();
                }
            });
        }
        function consultarContatosAbordados(pFiltros, pData, pCadencias) {
            $scope.tituloDoCard = "Contatos Abordados";
            $scope.code = "COAB";
            $scope.order = { order: 2 };
            var id = $scope.tituloDoCard.trim();
            var cardEstaNaTela = false;
            for (var i = 0; i < $scope.cards.length; i++) {
                if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                    $scope.cards[i].id = id;
                    $scope.cards[i].titulo = $scope.tituloDoCard;
                    $scope.cards[i].order = $scope.order;
                    $scope.cards[i].code = $scope.code;
                    $scope.cards[i].loader = true;
                    cardEstaNaTela = true;
                    break;
                }
            }
            if (!cardEstaNaTela) {
                $scope.cards.push({
                    id: id,
                    titulo: $scope.tituloDoCard,
                    order: $scope.order,
                    code: $scope.code,
                    loader: true,
                });
            }
            $http({
                method: "POST",
                url: "/relatorio/consultarContatosAbordados",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    listaTotalUsuarios: $scope.usuariosTime,
                    dateFilter: pData[0].data,
                    pObjectIdLogin: pFiltros,
                    pObjectIdDisparos: pCadencias,
                },
            }).then(function (response) {
                $scope.tituloDoCard = "Contatos Abordados";
                $scope.code = "COAB";
                $scope.order = { order: 2 };
                var id = $scope.tituloDoCard.trim();
                var cardEstaNaTela = false;
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = true;
                        cardEstaNaTela = true;
                        break;
                    }
                }
                $scope.valorDoCard = 0;
                $scope.valorAnteriorDoCard = 0;
                $scope.percMudanca = 0;
                $scope.maiorQueMil = false;
                var listaDisparosEmails = response.data;
                var arrayDadosMesAtual = [];
                var arrayDadosMesAnterior = [];
                var contMesAtual;
                var contMesAnterior;
                var dataInicial = new Date(montaData(pData[0].data).inicial);
                dataInicial.setDate(dataInicial.getDate() + 1);
                dataInicial.setHours(0);
                var listaDisparosEmailsAgrupadosPorContatos = agrupaArrayPorChave(listaDisparosEmails, "objectIdContato");
                for (var contato in listaDisparosEmailsAgrupadosPorContatos) {
                    var contMesAtual = false;
                    var contMesAnterior = false;
                    for (var i = 0; i < listaDisparosEmailsAgrupadosPorContatos[contato].length; i++) {
                        if (new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i].dataEnvio) >= new Date(pData[0].data) &&
                            !contMesAtual) {
                            var aux = 0;
                            aux = new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i].dataEnvio).getDate();
                            if (aux < 10)
                                aux = "0" + aux;
                            else
                                aux = aux.toString();
                            arrayDadosMesAtual.push(aux);
                            $scope.valorDoCard++;
                            contMesAtual = true;
                        }
                        else {
                            if (new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i].dataEnvio) >= dataInicial &&
                                new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i].dataEnvio) < new Date(pData[0].data) &&
                                !contMesAnterior) {
                                var aux = 0;
                                aux = new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i].dataEnvio).getDate();
                                if (aux < 10)
                                    aux = "0" + aux;
                                else
                                    aux = aux.toString();
                                arrayDadosMesAnterior.push(aux);
                                $scope.valorAnteriorDoCard++;
                                contMesAnterior = true;
                            }
                        }
                    }
                }
                $scope.listaContatosAbordados = arrayDadosMesAtual;
                $scope.listaContatosAbordadosMesAnterior = arrayDadosMesAnterior;
                if ($scope.valorDoCard !== 0 && $scope.valorAnteriorDoCard !== 0)
                    $scope.percMudanca =
                        (($scope.valorDoCard - $scope.valorAnteriorDoCard) /
                            $scope.valorAnteriorDoCard) *
                            100;
                if ($scope.percMudanca > 0) {
                    $scope.color = { "background-color": "#75c18d" };
                }
                else if ($scope.percMudanca == 0) {
                    $scope.color = { "background-color": "#a8a8a8" };
                }
                else {
                    $scope.color = { "background-color": "#ff5050" };
                }
                if ($scope.percMudanca >= 1000) {
                    $scope.maiorQueMil = true;
                }
                for (i = 0; i < $scope.cards.length; i++) {
                    // if ($scope.cards[i].titulo === 'Contatos Abordados') {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].valor = $scope.valorDoCard;
                        $scope.cards[i].valorAnterior = $scope.valorAnteriorDoCard;
                        $scope.cards[i].percMudanca = $scope.percMudanca;
                        $scope.cards[i].maiorQueMil = $scope.maiorQueMil;
                        $scope.cards[i].color = $scope.color;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = false;
                        break;
                    }
                }
                if ($scope.nomeGrafico == $scope.tituloDoCard) {
                    if ($scope.idGraficoAberto === 1)
                        $scope.abreGraficoAcumulativo();
                    if ($scope.idGraficoAberto === 2)
                        $scope.abreGraficoComparativo();
                }
            });
        }
        function consultarContatosEmailsAbertos(pFiltros, pData, pCadencias) {
            $scope.tituloDoCard = "Contatos - E-mails Abertos";
            $scope.code = "COEA";
            $scope.order = { order: 3 };
            var id = $scope.tituloDoCard.trim();
            var cardEstaNaTela = false;
            for (var i = 0; i < $scope.cards.length; i++) {
                if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                    $scope.cards[i].id = id;
                    $scope.cards[i].titulo = $scope.tituloDoCard;
                    $scope.cards[i].order = $scope.order;
                    $scope.cards[i].code = $scope.code;
                    $scope.cards[i].loader = true;
                    cardEstaNaTela = true;
                    break;
                }
            }
            if (!cardEstaNaTela) {
                $scope.cards.push({
                    id: id,
                    titulo: $scope.tituloDoCard,
                    order: $scope.order,
                    code: $scope.code,
                    loader: true,
                });
            }
            $http({
                method: "POST",
                url: "/relatorio/consultarContatosEmailsAbertos",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    listaTotalUsuarios: $scope.usuariosTime,
                    dateFilter: pData[0].data,
                    pObjectIdLogin: pFiltros,
                    pObjectIdDisparos: pCadencias,
                },
            }).then(function (response) {
                $scope.tituloDoCard = "Contatos - E-mails Abertos";
                $scope.code = "COEA";
                $scope.order = { order: 3 };
                var id = $scope.tituloDoCard.trim();
                var cardEstaNaTela = false;
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = true;
                        cardEstaNaTela = true;
                        break;
                    }
                }
                $scope.valorDoCard = 0;
                $scope.valorAnteriorDoCard = 0;
                $scope.percMudanca = 0;
                $scope.maiorQueMil = false;
                var listaDisparosEmails = response.data;
                var arrayDadosMesAtual = [];
                var arrayDadosMesAnterior = [];
                var contMesAtual;
                var contMesAnterior;
                var dataInicial = new Date(montaData(pData[0].data).inicial);
                dataInicial.setDate(dataInicial.getDate() + 1);
                dataInicial.setHours(0);
                var listaDisparosEmailsAgrupadosPorContatos = agrupaArrayPorChave(listaDisparosEmails, "objectIdContato");
                for (var contato in listaDisparosEmailsAgrupadosPorContatos) {
                    var contMesAtual = false;
                    var contMesAnterior = false;
                    for (var i = 0; i < listaDisparosEmailsAgrupadosPorContatos[contato].length; i++) {
                        if (new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                            .dataAbertura) >= new Date(pData[0].data) &&
                            !contMesAtual) {
                            var aux = 0;
                            aux = new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                .dataAbertura).getDate();
                            if (aux < 10)
                                aux = "0" + aux;
                            else
                                aux = aux.toString();
                            arrayDadosMesAtual.push(aux);
                            $scope.valorDoCard++;
                            contMesAtual = true;
                        }
                        else {
                            if (new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                .dataAbertura) >= dataInicial &&
                                new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                    .dataAbertura) < new Date(pData[0].data) &&
                                !contMesAnterior) {
                                var aux = 0;
                                aux = new Date(listaDisparosEmailsAgrupadosPorContatos[contato][i]
                                    .dataAbertura).getDate();
                                if (aux < 10)
                                    aux = "0" + aux;
                                else
                                    aux = aux.toString();
                                arrayDadosMesAnterior.push(aux);
                                $scope.valorAnteriorDoCard++;
                                contMesAnterior = true;
                            }
                        }
                    }
                }
                $scope.listaContatosEmailsAbertos = arrayDadosMesAtual;
                $scope.listaContatosEmailsAbertosAnterior = arrayDadosMesAnterior;
                if ($scope.valorDoCard !== 0 && $scope.valorAnteriorDoCard !== 0)
                    $scope.percMudanca =
                        (($scope.valorDoCard - $scope.valorAnteriorDoCard) /
                            $scope.valorAnteriorDoCard) *
                            100;
                if ($scope.percMudanca > 0) {
                    $scope.color = { "background-color": "#75c18d" };
                }
                else if ($scope.percMudanca == 0) {
                    $scope.color = { "background-color": "#a8a8a8" };
                }
                else {
                    $scope.color = { "background-color": "#ff5050" };
                }
                if ($scope.percMudanca >= 1000) {
                    $scope.maiorQueMil = true;
                }
                for (i = 0; i < $scope.cards.length; i++) {
                    // if ($scope.cards[i].titulo === 'Contatos Abordados') {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].valor = $scope.valorDoCard;
                        $scope.cards[i].valorAnterior = $scope.valorAnteriorDoCard;
                        $scope.cards[i].percMudanca = $scope.percMudanca;
                        $scope.cards[i].maiorQueMil = $scope.maiorQueMil;
                        $scope.cards[i].color = $scope.color;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = false;
                        break;
                    }
                }
                if ($scope.nomeGrafico == $scope.tituloDoCard) {
                    if ($scope.idGraficoAberto === 1)
                        $scope.abreGraficoAcumulativo();
                    if ($scope.idGraficoAberto === 2)
                        $scope.abreGraficoComparativo();
                }
            });
        }
        function consultarConfiabilidade(pFiltros, pData, pCadencias, pConfiabilidade) {
            if (pConfiabilidade == "alta") {
                $scope.tituloDoCard = "Alta Confiabilidade";
                $scope.code = "COAL";
                $scope.order = { order: 4 };
            }
            else if (pConfiabilidade == "media") {
                $scope.tituloDoCard = "Média Confiabilidade";
                $scope.code = "COME";
                $scope.order = { order: 5 };
            }
            else if (pConfiabilidade == "baixa") {
                $scope.tituloDoCard = "Baixa Confiabilidade";
                $scope.code = "COBA";
                $scope.order = { order: 6 };
            }
            else if (pConfiabilidade == "sem") {
                $scope.tituloDoCard = "Sem Confiabilidade";
                $scope.code = "COSE";
                $scope.order = { order: 7 };
            }
            var id = $scope.tituloDoCard.trim();
            var cardEstaNaTela = false;
            for (var i = 0; i < $scope.cards.length; i++) {
                if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                    $scope.cards[i].id = id;
                    $scope.cards[i].titulo = $scope.tituloDoCard;
                    $scope.cards[i].order = $scope.order;
                    $scope.cards[i].code = $scope.code;
                    $scope.cards[i].loader = true;
                    cardEstaNaTela = true;
                    break;
                }
            }
            if (!cardEstaNaTela) {
                $scope.cards.push({
                    id: id,
                    titulo: $scope.tituloDoCard,
                    order: $scope.order,
                    code: $scope.code,
                    loader: true,
                });
            }
            $http({
                method: "POST",
                url: "/relatorio/consultarConfiabilidade",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    dateFilter: pData[0].data,
                    pGrauConfiabilidade: pConfiabilidade,
                    pObjectIdLogin: pFiltros,
                    pObjectIdDisparos: pCadencias,
                    listaTotalUsuarios: $scope.usuariosTime,
                },
            }).then(function (response) {
                if (pConfiabilidade == "alta") {
                    $scope.tituloDoCard = "Alta Confiabilidade";
                    $scope.code = "COAL";
                    $scope.order = { order: 4 };
                }
                else if (pConfiabilidade == "media") {
                    $scope.tituloDoCard = "Média Confiabilidade";
                    $scope.code = "COME";
                    $scope.order = { order: 5 };
                }
                else if (pConfiabilidade == "baixa") {
                    $scope.tituloDoCard = "Baixa Confiabilidade";
                    $scope.code = "COBA";
                    $scope.order = { order: 6 };
                }
                else if (pConfiabilidade == "sem") {
                    $scope.tituloDoCard = "Sem Confiabilidade";
                    $scope.code = "COSE";
                    $scope.order = { order: 7 };
                }
                var id = $scope.tituloDoCard.trim();
                var cardEstaNaTela = false;
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = true;
                        cardEstaNaTela = true;
                        break;
                    }
                }
                $scope.valorDoCard = 0;
                $scope.valorAnteriorDoCard = 0;
                $scope.percMudanca = 0;
                $scope.maiorQueMil = false;
                var arrayDadosMesAtual = [];
                var arrayDadosMesAnterior = [];
                for (var i = 0; i < response.data.length; i++) {
                    if (new Date(response.data[i].dataInclusao) >= new Date(pData[0].data)) {
                        var aux = 0;
                        aux = new Date(response.data[i].dataInclusao).getDate();
                        if (aux < 10)
                            aux = "0" + aux;
                        else
                            aux = aux.toString();
                        arrayDadosMesAtual.push(aux);
                        $scope.valorDoCard++;
                    }
                    else {
                        var aux = 0;
                        aux = new Date(response.data[i].dataInclusao).getDate();
                        if (aux < 10)
                            aux = "0" + aux;
                        else
                            aux = aux.toString();
                        arrayDadosMesAnterior.push(aux);
                        $scope.valorAnteriorDoCard++;
                    }
                }
                if (pConfiabilidade == "alta") {
                    $scope.listaConfiabilidadeAlta = arrayDadosMesAtual;
                    $scope.listaConfiabilidadeAltaMesAnterior = arrayDadosMesAnterior;
                }
                else if (pConfiabilidade == "media") {
                    $scope.listaConfiabilidadeMedia = arrayDadosMesAtual;
                    $scope.listaConfiabilidadeMediaMesAnterior = arrayDadosMesAnterior;
                }
                else if (pConfiabilidade == "baixa") {
                    $scope.listaConfiabilidadeBaixa = arrayDadosMesAtual;
                    $scope.listaConfiabilidadeBaixaMesAnterior = arrayDadosMesAnterior;
                }
                else if (pConfiabilidade == "sem") {
                    $scope.listaConfiabilidadeSem = arrayDadosMesAtual;
                    $scope.listaConfiabilidadeSemMesAnterior = arrayDadosMesAnterior;
                }
                if ($scope.valorDoCard !== 0 && $scope.valorAnteriorDoCard !== 0)
                    $scope.percMudanca =
                        (($scope.valorDoCard - $scope.valorAnteriorDoCard) /
                            $scope.valorAnteriorDoCard) *
                            100;
                if ($scope.percMudanca > 0) {
                    $scope.color = { "background-color": "#75c18d" };
                }
                else if ($scope.percMudanca == 0) {
                    $scope.color = { "background-color": "#a8a8a8" };
                }
                else {
                    $scope.color = { "background-color": "#ff5050" };
                }
                if ($scope.percMudanca >= 1000) {
                    $scope.maiorQueMil = true;
                }
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].valor = $scope.valorDoCard;
                        $scope.cards[i].valorAnterior = $scope.valorAnteriorDoCard;
                        $scope.cards[i].percMudanca = $scope.percMudanca;
                        $scope.cards[i].maiorQueMil = $scope.maiorQueMil;
                        $scope.cards[i].color = $scope.color;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = false;
                        break;
                    }
                }
                if ($scope.nomeGrafico == $scope.tituloDoCard) {
                    if ($scope.idGraficoAberto === 1)
                        $scope.abreGraficoAcumulativo();
                    if ($scope.idGraficoAberto === 2)
                        $scope.abreGraficoComparativo();
                }
            });
        }
        function consultarCadenciasAtivas(pFiltros, pData, pCadencias) {
            $scope.tituloDoCard = "Cadências Ativas";
            $scope.order = { order: 8 };
            $scope.code = "CAAT";
            var id = $scope.tituloDoCard.trim();
            var cardEstaNaTela = false;
            for (var i = 0; i < $scope.cards.length; i++) {
                if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                    $scope.cards[i].id = id;
                    $scope.cards[i].titulo = $scope.tituloDoCard;
                    $scope.cards[i].order = $scope.order;
                    $scope.cards[i].code = $scope.code;
                    $scope.cards[i].loader = true;
                    cardEstaNaTela = true;
                    break;
                }
            }
            if (!cardEstaNaTela) {
                $scope.cards.push({
                    id: id,
                    titulo: $scope.tituloDoCard,
                    order: $scope.order,
                    code: $scope.code,
                    loader: true,
                });
            }
            $http({
                method: "POST",
                url: "/relatorio/consultarCadenciasAtivas",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    pObjectIdLogin: pFiltros,
                },
            }).then(function (response) {
                $scope.tituloDoCard = "Cadências Ativas";
                $scope.order = { order: 8 };
                $scope.code = "CAAT";
                var id = $scope.tituloDoCard.trim();
                var cardEstaNaTela = false;
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = true;
                        cardEstaNaTela = true;
                        break;
                    }
                }
                $scope.valorDoCard = 0;
                $scope.valorAnteriorDoCard = 0;
                $scope.percMudanca = 0;
                var arrayDadosMesAtual = [];
                var arrayDadosMesAnterior = [];
                for (var i = 0; i < response.data.length; i++) {
                    var aux = 0;
                    aux = new Date(response.data[i].dataInicio).getDate();
                    if (aux < 10)
                        aux = "0" + aux;
                    else
                        aux = aux.toString();
                    arrayDadosMesAtual.push(response.data[i]);
                    $scope.valorDoCard++;
                }
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].valor = $scope.valorDoCard;
                        $scope.cards[i].valorAnterior = $scope.valorAnteriorDoCard;
                        $scope.cards[i].percMudanca = $scope.percMudanca;
                        $scope.cards[i].color = $scope.color;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = false;
                        break;
                    }
                }
                if ($scope.nomeGrafico == $scope.tituloDoCard) {
                    if ($scope.idGraficoAberto === 1)
                        $scope.abreGraficoAcumulativo();
                    if ($scope.idGraficoAberto === 2)
                        $scope.abreGraficoComparativo();
                }
            });
        }
        function consultarEnviosEmails(pFiltros, pData, pCadencias, pStatus) {
            if (pStatus == "ENV") {
                $scope.tituloDoCard = "E-mails Enviados";
                $scope.order = { order: 9 };
                $scope.code = "EMEN";
            }
            else if (pStatus == "E") {
                $scope.tituloDoCard = "E-mails Entregues";
                $scope.order = { order: 10 };
                $scope.code = "EMET";
            }
            else if (pStatus == "B") {
                $scope.tituloDoCard = "E-mails Bounces";
                $scope.order = { order: 11 };
                $scope.code = "EMBO";
            }
            else if (pStatus == "A") {
                $scope.tituloDoCard = "E-mails Abertos";
                $scope.order = { order: 12 };
                $scope.code = "EMAB";
            }
            var id = $scope.tituloDoCard.trim();
            var cardEstaNaTela = false;
            for (var i = 0; i < $scope.cards.length; i++) {
                if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                    $scope.cards[i].id = id;
                    $scope.cards[i].titulo = $scope.tituloDoCard;
                    $scope.cards[i].order = $scope.order;
                    $scope.cards[i].code = $scope.code;
                    $scope.cards[i].loader = true;
                    cardEstaNaTela = true;
                    break;
                }
            }
            if (!cardEstaNaTela) {
                $scope.cards.push({
                    id: id,
                    titulo: $scope.tituloDoCard,
                    order: $scope.order,
                    code: $scope.code,
                    loader: true,
                });
            }
            $http({
                method: "POST",
                url: "/relatorio/consultarEnviosEmails",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    dateFilter: pData[0].data,
                    pObjectIdLogin: pFiltros,
                    pObjectIdDisparos: pCadencias,
                    pStatus: pStatus,
                    listaTotalUsuarios: $scope.usuariosTime,
                },
            }).then(function (response) {
                if (pStatus == "ENV") {
                    $scope.tituloDoCard = "E-mails Enviados";
                    $scope.order = { order: 9 };
                    $scope.code = "EMEN";
                }
                else if (pStatus == "E") {
                    $scope.tituloDoCard = "E-mails Entregues";
                    $scope.order = { order: 10 };
                    $scope.code = "EMET";
                }
                else if (pStatus == "B") {
                    $scope.tituloDoCard = "E-mails Bounces";
                    $scope.order = { order: 11 };
                    $scope.code = "EMBO";
                }
                else if (pStatus == "A") {
                    $scope.tituloDoCard = "E-mails Abertos";
                    $scope.order = { order: 12 };
                    $scope.code = "EMAB";
                }
                var id = $scope.tituloDoCard.trim();
                var cardEstaNaTela = false;
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = true;
                        cardEstaNaTela = true;
                        break;
                    }
                }
                $scope.valorDoCard = 0;
                $scope.valorAnteriorDoCard = 0;
                $scope.percMudanca = 0;
                $scope.maiorQueMil = false;
                var arrayDadosMesAtual = [];
                var arrayDadosMesAnterior = [];
                if (pStatus != "A") {
                    for (var i = 0; i < response.data.length; i++) {
                        if (new Date(response.data[i].dataEnvio) >= new Date(pData[0].data)) {
                            var aux = 0;
                            aux = new Date(response.data[i].dataEnvio).getDate();
                            if (aux < 10)
                                aux = "0" + aux;
                            else
                                aux = aux.toString();
                            arrayDadosMesAtual.push(aux);
                            $scope.valorDoCard++;
                        }
                        else {
                            var aux = 0;
                            aux = new Date(response.data[i].dataEnvio).getDate();
                            if (aux < 10)
                                aux = "0" + aux;
                            else
                                aux = aux.toString();
                            arrayDadosMesAnterior.push(aux);
                            $scope.valorAnteriorDoCard++;
                        }
                    }
                }
                else {
                    for (var i = 0; i < response.data.length; i++) {
                        if (new Date(response.data[i].dataAbertura) >= new Date(pData[0].data)) {
                            var aux = 0;
                            aux = new Date(response.data[i].dataAbertura).getDate();
                            if (aux < 10)
                                aux = "0" + aux;
                            else
                                aux = aux.toString();
                            arrayDadosMesAtual.push(aux);
                            $scope.valorDoCard++;
                        }
                        else {
                            var aux = 0;
                            aux = new Date(response.data[i].dataAbertura).getDate();
                            if (aux < 10)
                                aux = "0" + aux;
                            else
                                aux = aux.toString();
                            arrayDadosMesAnterior.push(aux);
                            $scope.valorAnteriorDoCard++;
                        }
                    }
                }
                if (pStatus == "ENV") {
                    $scope.listaEmailEnviado = arrayDadosMesAtual;
                    $scope.listaEmailEnviadoMesAnterior = arrayDadosMesAnterior;
                }
                else if (pStatus == "E") {
                    $scope.listaEmailEntregue = arrayDadosMesAtual;
                    $scope.listaEmailEntregueMesAnterior = arrayDadosMesAnterior;
                }
                else if (pStatus == "B") {
                    $scope.listaEmailBounce = arrayDadosMesAtual;
                    $scope.listaEmailBounceMesAnterior = arrayDadosMesAnterior;
                }
                else if (pStatus == "A") {
                    $scope.listaEmailAberto = arrayDadosMesAtual;
                    $scope.listaEmailAbertoMesAnterior = arrayDadosMesAnterior;
                }
                if ($scope.valorDoCard !== 0 && $scope.valorAnteriorDoCard !== 0)
                    $scope.percMudanca =
                        (($scope.valorDoCard - $scope.valorAnteriorDoCard) /
                            $scope.valorAnteriorDoCard) *
                            100;
                if ($scope.percMudanca >= 1000) {
                    $scope.maiorQueMil = true;
                }
                if ($scope.percMudanca > 0) {
                    $scope.color = { "background-color": "#75c18d" };
                }
                else if ($scope.percMudanca == 0) {
                    $scope.color = { "background-color": "#a8a8a8" };
                }
                else {
                    $scope.color = { "background-color": "#ff5050" };
                }
                // Regras especiais para o Bounce já que ter menos bounce que o período anterior é algo bom
                if (pStatus == "B") {
                    if ($scope.percMudanca > 0) {
                        $scope.color = { "background-color": "#ff5050" };
                    }
                    if ($scope.percMudanca < 0) {
                        $scope.color = { "background-color": "#75c18d" };
                    }
                }
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].valor = $scope.valorDoCard;
                        $scope.cards[i].valorAnterior = $scope.valorAnteriorDoCard;
                        $scope.cards[i].percMudanca = $scope.percMudanca;
                        $scope.cards[i].maiorQueMil = $scope.maiorQueMil;
                        $scope.cards[i].color = $scope.color;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = false;
                        break;
                    }
                }
                if ($scope.nomeGrafico == $scope.tituloDoCard) {
                    if ($scope.idGraficoAberto === 1)
                        $scope.abreGraficoAcumulativo();
                    if ($scope.idGraficoAberto === 2)
                        $scope.abreGraficoComparativo();
                }
            });
        }
        function consultarRespostas(pFiltros, pData, pCadencias, pStatus) {
            if (pStatus == "P") {
                $scope.tituloDoCard = "Respostas Positivas";
                $scope.order = { order: 13 };
                $scope.code = "REPO";
            }
            else if (pStatus == "C") {
                $scope.tituloDoCard = "Respostas Neutras";
                $scope.order = { order: 14 };
                $scope.code = "RENU";
            }
            else if (pStatus == "N") {
                $scope.tituloDoCard = "Respostas Negativas";
                $scope.order = { order: 15 };
                $scope.code = "RENE";
            }
            else if (pStatus == "T") {
                $scope.tituloDoCard = "Total de Respostas";
                $scope.order = { order: 16 };
                $scope.code = "TORE";
            }
            var id = $scope.tituloDoCard.trim();
            var cardEstaNaTela = false;
            for (var i = 0; i < $scope.cards.length; i++) {
                if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                    $scope.cards[i].id = id;
                    $scope.cards[i].titulo = $scope.tituloDoCard;
                    $scope.cards[i].order = $scope.order;
                    $scope.cards[i].code = $scope.code;
                    $scope.cards[i].loader = true;
                    cardEstaNaTela = true;
                    break;
                }
            }
            if (!cardEstaNaTela) {
                $scope.cards.push({
                    id: id,
                    titulo: $scope.tituloDoCard,
                    order: $scope.order,
                    code: $scope.code,
                    loader: true,
                });
            }
            $http({
                method: "POST",
                url: "/relatorio/consultarRespostas",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    dateFilter: pData[0].data,
                    pStatus: pStatus,
                    pObjectIdLogin: pFiltros,
                    pObjectIdDisparos: pCadencias,
                    listaTotalUsuarios: $scope.usuariosTime,
                },
            }).then(function (response) {
                if (pStatus == "P") {
                    $scope.tituloDoCard = "Respostas Positivas";
                    $scope.order = { order: 13 };
                    $scope.code = "REPO";
                }
                else if (pStatus == "C") {
                    $scope.tituloDoCard = "Respostas Neutras";
                    $scope.order = { order: 14 };
                    $scope.code = "RENU";
                }
                else if (pStatus == "N") {
                    $scope.tituloDoCard = "Respostas Negativas";
                    $scope.order = { order: 15 };
                    $scope.code = "RENE";
                }
                else if (pStatus == "T") {
                    $scope.tituloDoCard = "Total de Respostas";
                    $scope.order = { order: 16 };
                    $scope.code = "TORE";
                }
                var id = $scope.tituloDoCard.trim();
                var cardEstaNaTela = false;
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = true;
                        cardEstaNaTela = true;
                        break;
                    }
                }
                $scope.valorDoCard = 0;
                $scope.valorAnteriorDoCard = 0;
                $scope.percMudanca = 0;
                $scope.maiorQueMil = false;
                var arrayDadosMesAtual = [];
                var arrayDadosMesAnterior = [];
                for (var i = 0; i < response.data.length; i++) {
                    if (new Date(response.data[i].dataResposta) >= new Date(pData[0].data)) {
                        var aux = 0;
                        aux = new Date(response.data[i].dataResposta).getDate();
                        if (aux < 10)
                            aux = "0" + aux;
                        else
                            aux = aux.toString();
                        arrayDadosMesAtual.push(aux);
                        $scope.valorDoCard++;
                    }
                    else {
                        var aux = 0;
                        aux = new Date(response.data[i].dataResposta).getDate();
                        if (aux < 10)
                            aux = "0" + aux;
                        else
                            aux = aux.toString();
                        arrayDadosMesAnterior.push(aux);
                        $scope.valorAnteriorDoCard++;
                    }
                }
                if (pStatus == "P") {
                    $scope.listaRespostaPositiva = arrayDadosMesAtual;
                    $scope.listaRespostaPositivaMesAnterior = arrayDadosMesAnterior;
                }
                else if (pStatus == "C") {
                    $scope.listaRespostaNeutra = arrayDadosMesAtual;
                    $scope.listaRespostaNeutraMesAnterior = arrayDadosMesAnterior;
                }
                else if (pStatus == "N") {
                    $scope.listaRespostaNegativa = arrayDadosMesAtual;
                    $scope.listaRespostaNegativaMesAnterior = arrayDadosMesAnterior;
                }
                else if (pStatus == "T") {
                    $scope.listaRespostaTotal = arrayDadosMesAtual;
                    $scope.listaRespostaTotalMesAnterior = arrayDadosMesAnterior;
                }
                if ($scope.valorDoCard !== 0 && $scope.valorAnteriorDoCard !== 0)
                    $scope.percMudanca =
                        (($scope.valorDoCard - $scope.valorAnteriorDoCard) /
                            $scope.valorAnteriorDoCard) *
                            100;
                if ($scope.percMudanca > 0) {
                    $scope.color = { "background-color": "#75c18d" };
                }
                else if ($scope.percMudanca == 0) {
                    $scope.color = { "background-color": "#a8a8a8" };
                }
                else {
                    $scope.color = { "background-color": "#ff5050" };
                }
                if ($scope.percMudanca >= 1000) {
                    $scope.maiorQueMil = true;
                }
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].valor = $scope.valorDoCard;
                        $scope.cards[i].valorAnterior = $scope.valorAnteriorDoCard;
                        $scope.cards[i].percMudanca = $scope.percMudanca;
                        $scope.cards[i].maiorQueMil = $scope.maiorQueMil;
                        $scope.cards[i].color = $scope.color;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = false;
                        break;
                    }
                }
                if ($scope.nomeGrafico == $scope.tituloDoCard) {
                    if ($scope.idGraficoAberto === 1)
                        $scope.abreGraficoAcumulativo();
                    if ($scope.idGraficoAberto === 2)
                        $scope.abreGraficoComparativo();
                }
            });
        }
        function consultarJanelasOportunidades(pFiltros, pData, pCadencias) {
            $scope.tituloDoCard = "Janela de Atenção";
            $scope.code = "JAOP";
            $scope.order = { order: 17 };
            var id = $scope.tituloDoCard.trim();
            var cardEstaNaTela = false;
            for (var i = 0; i < $scope.cards.length; i++) {
                if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                    $scope.cards[i].id = id;
                    $scope.cards[i].titulo = $scope.tituloDoCard;
                    $scope.cards[i].order = $scope.order;
                    $scope.cards[i].code = $scope.code;
                    $scope.cards[i].loader = true;
                    cardEstaNaTela = true;
                    break;
                }
            }
            if (!cardEstaNaTela) {
                $scope.cards.push({
                    id: id,
                    titulo: $scope.tituloDoCard,
                    order: $scope.order,
                    code: $scope.code,
                    loader: true,
                });
            }
            $http({
                method: "POST",
                url: "/relatorio/consultarJanelasOportunidades",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    dateFilter: pData[0].data,
                    pObjectIdLogin: pFiltros,
                    pObjectIdDisparos: pCadencias,
                    listaTotalUsuarios: $scope.usuariosTime,
                },
            }).then(function (response) {
                $scope.tituloDoCard = "Janela de Atenção";
                $scope.code = "JAOP";
                $scope.order = { order: 17 };
                var id = $scope.tituloDoCard.trim();
                var cardEstaNaTela = false;
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = true;
                        cardEstaNaTela = true;
                        break;
                    }
                }
                $scope.valorDoCard = 0;
                $scope.valorAnteriorDoCard = 0;
                $scope.percMudanca = 0;
                $scope.maiorQueMil = false;
                var arrayDadosMesAtual = [];
                var arrayDadosMesAnterior = [];
                var dataInicial = new Date(montaData(pData[0].data).inicial);
                var contMesAtual;
                var contMesAnterior;
                var listaContatos = response.data;
                for (var i = 0; i < listaContatos.length; i++) {
                    contMesAtual = false;
                    contMesAnterior = false;
                    for (var j = 0; j < listaContatos[i].oportunidadeConexao.dataEntrada.length; j++) {
                        if (new Date(listaContatos[i].oportunidadeConexao.dataEntrada[j]) >=
                            new Date(pData[0].data) &&
                            !contMesAtual) {
                            var aux = 0;
                            aux = new Date(listaContatos[i].oportunidadeConexao.dataEntrada[j]).getDate();
                            if (aux < 10)
                                aux = "0" + aux;
                            else
                                aux = aux.toString();
                            arrayDadosMesAtual.push(aux);
                            $scope.valorDoCard++;
                            contMesAtual = true;
                        }
                        else {
                            if (new Date(listaContatos[i].oportunidadeConexao.dataEntrada[j] >
                                dataInicial) &&
                                new Date(listaContatos[i].oportunidadeConexao.dataEntrada[j]) <
                                    new Date(pData[0].data) &&
                                !contMesAnterior) {
                                var aux = 0;
                                aux = new Date(listaContatos[i].oportunidadeConexao.dataEntrada[j]).getDate();
                                if (aux < 10)
                                    aux = "0" + aux;
                                else
                                    aux = aux.toString();
                                arrayDadosMesAnterior.push(aux);
                                $scope.valorAnteriorDoCard++;
                                contMesAnterior = true;
                            }
                        }
                    }
                }
                $scope.listaJanelaOportunidade = arrayDadosMesAtual;
                $scope.listaJanelaOportunidadeMesAnterior = arrayDadosMesAnterior;
                if ($scope.valorDoCard !== 0 && $scope.valorAnteriorDoCard !== 0)
                    $scope.percMudanca =
                        (($scope.valorDoCard - $scope.valorAnteriorDoCard) /
                            $scope.valorAnteriorDoCard) *
                            100;
                if ($scope.percMudanca > 0) {
                    $scope.color = { "background-color": "#75c18d" };
                }
                else if ($scope.percMudanca == 0) {
                    $scope.color = { "background-color": "#a8a8a8" };
                }
                else {
                    $scope.color = { "background-color": "#ff5050" };
                }
                if ($scope.percMudanca >= 1000) {
                    $scope.maiorQueMil = true;
                }
                for (var i = 0; i < $scope.cards.length; i++) {
                    if ($scope.cards[i].titulo === $scope.tituloDoCard) {
                        $scope.cards[i].id = id;
                        $scope.cards[i].titulo = $scope.tituloDoCard;
                        $scope.cards[i].valor = $scope.valorDoCard;
                        $scope.cards[i].valorAnterior = $scope.valorAnteriorDoCard;
                        $scope.cards[i].percMudanca = $scope.percMudanca;
                        $scope.cards[i].maiorQueMil = $scope.maiorQueMil;
                        $scope.cards[i].color = $scope.color;
                        $scope.cards[i].order = $scope.order;
                        $scope.cards[i].code = $scope.code;
                        $scope.cards[i].loader = false;
                        break;
                    }
                }
                if ($scope.nomeGrafico == $scope.tituloDoCard) {
                    if ($scope.idGraficoAberto === 1)
                        $scope.abreGraficoAcumulativo();
                    if ($scope.idGraficoAberto === 2)
                        $scope.abreGraficoComparativo();
                }
            });
        }
        function consultarTempoLigacao(pFiltros, pData, pCadencias) {
            // completar ...
        }
        function consultarConexoesConectar(pFiltros, pData, pCadencias) {
            // completar ...
        }
        function consultarConversoesConectar(pFiltros, pData, pCadencias) {
            // completar ...
        }
        function consultarUsuariosTimeComCadencia() {
            $http({
                method: "GET",
                url: "/relatorio/consultarUsuariosTimeComCadencia",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (response) {
                // console.log(response);
            });
        }
        $scope.nomeGrafico = "";
        $scope.card = "";
        var chart = null;
        $scope.abreGrafico = function (card) {
            var cardEmDestaque = document.getElementById("card" + $scope.card.code);
            $(cardEmDestaque).removeClass("cardDestaque");
            $scope.card = card;
            if ($scope.nomeGrafico == card.titulo) {
                $scope.abrirGrafico = false;
                $scope.nomeGrafico = "";
                $scope.idGraficoAberto = 0;
                var tituloGraficoAcumulativo = document.getElementById("idTituloGraficoAcumulativo");
                $(tituloGraficoAcumulativo).removeClass("bold");
                $scope.textoGraficoAcumulativo = false;
                var tituloGraficoComparativo = document.getElementById("idTituloGraficoComparativo");
                $(tituloGraficoComparativo).removeClass("bold");
                $scope.textoGraficoComparativo = false;
            }
            else {
                $scope.abrirGrafico = true;
                $scope.nomeGrafico = card.titulo;
                $scope.abreGraficoAcumulativo();
                var cardEmDestaque = document.getElementById("card" + card.code);
                $(cardEmDestaque).addClass("cardDestaque");
                $scope.selecionaRanking(card.code);
            }
        };
        $scope.abreGraficoAcumulativo = function () {
            $scope.idGraficoAberto = 1;
            var arrayUmAtual = [];
            var arrayDoisAtual = [];
            var arrayUmAnterior = [];
            var arrayDoisAnterior = [];
            var escolheuMesAtual = true;
            var arrayData = [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ];
            var dataAtual = new Date();
            if ($scope.anoFront == dataAtual.getFullYear() &&
                $scope.mesFront == meses[dataAtual.getMonth()]) {
                escolheuMesAtual = true;
            }
            else {
                escolheuMesAtual = false;
            }
            if ($scope.card.titulo === "Contatos Capturados") {
                arrayUmAtual = $scope.listaContatosCapturados;
                arrayUmAnterior = $scope.listaContatosCapturadosMesAnterior;
            }
            else if ($scope.card.titulo === "Contatos - E-mails Abertos") {
                arrayUmAtual = $scope.listaContatosEmailsAbertos;
                arrayUmAnterior = $scope.listaContatosEmailsAbertosAnterior;
            }
            else if ($scope.card.titulo === "Contatos Abordados") {
                arrayUmAtual = $scope.listaContatosAbordados;
                arrayUmAnterior = $scope.listaContatosAbordadosMesAnterior;
            }
            else if ($scope.card.titulo === "Alta Confiabilidade") {
                arrayUmAtual = $scope.listaConfiabilidadeAlta;
                arrayUmAnterior = $scope.listaConfiabilidadeAltaMesAnterior;
            }
            else if ($scope.card.titulo === "Média Confiabilidade") {
                arrayUmAtual = $scope.listaConfiabilidadeMedia;
                arrayUmAnterior = $scope.listaConfiabilidadeMediaMesAnterior;
            }
            else if ($scope.card.titulo === "Baixa Confiabilidade") {
                arrayUmAtual = $scope.listaConfiabilidadeBaixa;
                arrayUmAnterior = $scope.listaConfiabilidadeBaixaMesAnterior;
            }
            else if ($scope.card.titulo === "Sem Confiabilidade") {
                arrayUmAtual = $scope.listaConfiabilidadeSem;
                arrayUmAnterior = $scope.listaConfiabilidadeSemMesAnterior;
            }
            else if ($scope.card.titulo === "E-mails Enviados") {
                arrayUmAtual = $scope.listaEmailEnviado;
                arrayUmAnterior = $scope.listaEmailEnviadoMesAnterior;
            }
            else if ($scope.card.titulo === "E-mails Entregues") {
                arrayUmAtual = $scope.listaEmailEntregue;
                arrayUmAnterior = $scope.listaEmailEntregueMesAnterior;
            }
            else if ($scope.card.titulo === "E-mails Bounces") {
                arrayUmAtual = $scope.listaEmailBounce;
                arrayUmAnterior = $scope.listaEmailBounceMesAnterior;
            }
            else if ($scope.card.titulo === "E-mails Abertos") {
                arrayUmAtual = $scope.listaEmailAberto;
                arrayUmAnterior = $scope.listaEmailAbertoMesAnterior;
            }
            else if ($scope.card.titulo === "Respostas Positivas") {
                arrayUmAtual = $scope.listaRespostaPositiva;
                arrayUmAnterior = $scope.listaRespostaPositivaMesAnterior;
            }
            else if ($scope.card.titulo === "Respostas Neutras") {
                arrayUmAtual = $scope.listaRespostaNeutra;
                arrayUmAnterior = $scope.listaRespostaNeutraMesAnterior;
            }
            else if ($scope.card.titulo === "Respostas Negativas") {
                arrayUmAtual = $scope.listaRespostaNegativa;
                arrayUmAnterior = $scope.listaRespostaNegativaMesAnterior;
            }
            else if ($scope.card.titulo === "Total de Respostas") {
                arrayUmAtual = $scope.listaRespostaTotal;
                arrayUmAnterior = $scope.listaRespostaTotalMesAnterior;
            }
            else if ($scope.card.titulo === "Janela de Atenção") {
                arrayUmAtual = $scope.listaJanelaOportunidade;
                arrayUmAnterior = $scope.listaJanelaOportunidadeMesAnterior;
            }
            arrayUmAtual = arrayUmAtual.sort();
            arrayUmAnterior = arrayUmAnterior.sort();
            var contAuxAtual = 0;
            var contAuxAnterior = 0;
            for (var i = 1; i <= 31; i++) {
                var contDiasAtual = 0;
                var contDiasAnterior = 0;
                var contArrayMaior = 0;
                if (arrayUmAtual.length >= arrayUmAnterior.length)
                    contArrayMaior = arrayUmAtual.length;
                else
                    contArrayMaior = arrayUmAnterior.length;
                for (var j = 0; j <= contArrayMaior; j++) {
                    if (arrayUmAtual[j] == i) {
                        contDiasAtual++;
                    }
                    if (arrayUmAnterior[j] == i) {
                        contDiasAnterior++;
                    }
                }
                if (["Janeiro", "Agosto"].includes($scope.mesFront)) {
                    contAuxAtual += contDiasAtual;
                    arrayDoisAtual.push({ x: i, y: contAuxAtual });
                    contAuxAnterior += contDiasAnterior;
                    arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                }
                else if (["Maio", "Julho", "Outubro", "Dezembro"].includes($scope.mesFront)) {
                    if (i == 31) {
                        contAuxAtual += contDiasAtual;
                        arrayDoisAtual.push({ x: i, y: contAuxAtual });
                        arrayDoisAnterior.push({ x: i, y: undefined });
                    }
                    else {
                        contAuxAtual += contDiasAtual;
                        arrayDoisAtual.push({ x: i, y: contAuxAtual });
                        contAuxAnterior += contDiasAnterior;
                        arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                    }
                }
                else if (["Abril", "Junho", "Setembro", "Novembro"].includes($scope.mesFront)) {
                    if (i == 31) {
                        contAuxAnterior += contDiasAnterior;
                        arrayDoisAtual.push({ x: i, y: undefined });
                        arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                    }
                    else {
                        contAuxAtual += contDiasAtual;
                        arrayDoisAtual.push({ x: i, y: contAuxAtual });
                        contAuxAnterior += contDiasAnterior;
                        arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                    }
                }
                else if (["Fevereiro"].includes($scope.mesFront)) {
                    // ano bisexto
                    if ($scope.anoFront % 4 == 0) {
                        if (i == 30 || i == 31) {
                            contAuxAnterior += contDiasAnterior;
                            arrayDoisAtual.push({ x: i, y: undefined });
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                        else {
                            contAuxAtual += contDiasAtual;
                            arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            contAuxAnterior += contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                    }
                    else {
                        if (i == 29 || i == 30 || i == 31) {
                            contAuxAnterior += contDiasAnterior;
                            arrayDoisAtual.push({ x: i, y: undefined });
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                        else {
                            contAuxAtual += contDiasAtual;
                            arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            contAuxAnterior += contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                    }
                }
                else if (["Março"].includes($scope.mesFront)) {
                    // ano bisexto
                    if ($scope.anoFront % 4 == 0) {
                        if (i == 30 || i == 31) {
                            contAuxAtual += contDiasAtual;
                            arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            arrayDoisAnterior.push({ x: i, y: undefined });
                        }
                        else {
                            contAuxAtual += contDiasAtual;
                            arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            contAuxAnterior += contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                    }
                    else {
                        if (i == 29 || i == 30 || i == 31) {
                            contAuxAtual += contDiasAtual;
                            arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            arrayDoisAnterior.push({ x: i, y: undefined });
                        }
                        else {
                            contAuxAtual += contDiasAtual;
                            arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            contAuxAnterior += contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                    }
                }
                // contAuxAtual += contDiasAtual;
                // contAuxAnterior += contDiasAnterior;
                // arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                // arrayDoisAtual.push({ x: i, y: contAuxAtual });
            }
            var options = {
                chart: {
                    type: "area",
                    height: "230px",
                    width: "100%",
                    toolbar: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: "Dados deste mês (" + $scope.mesFront + ")",
                        data: arrayDoisAtual,
                    },
                    {
                        name: "Comparativo com o mês anterior (" +
                            $scope.mesAnteriorFront +
                            ")",
                        data: arrayDoisAnterior,
                    },
                ],
                labels: arrayData,
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100],
                    },
                },
                stroke: {
                    curve: "straight",
                    width: 1,
                },
                markers: {
                    size: 3,
                },
                xaxis: {
                    type: "text",
                    lines: {
                        show: true,
                    },
                    categories: arrayData,
                    labels: {
                        style: {
                            fontSize: "10px",
                            colors: "#adadad",
                        },
                        offsetY: -4,
                    },
                    tickAmount: 30,
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                    tickAmount: 4,
                    show: false,
                    floating: false,
                    labels: {
                        style: {
                            fontSize: "10px",
                            color: "#adadad",
                        },
                        formatter: function (value, timestamp, index) {
                            if (value == undefined)
                                return undefined;
                            return value + "";
                        },
                    },
                },
                legend: {
                    position: "bottom",
                    horizontalAlign: "right",
                    offsetY: -10,
                    fontSize: "10px",
                    labels: {
                        colors: "#adadad",
                    },
                    markers: {
                        width: 8,
                        height: 8,
                        radius: 0,
                    },
                },
                grid: {
                    borderColor: "#c8c8c873",
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                tooltip: {
                    marker: {
                        show: true,
                    },
                    followCursor: true,
                    x: {
                        show: false,
                    },
                    shared: true,
                },
                colors: ["#96d7aa", "#9c9c9c"],
            };
            var tituloGraficoAcumulativo = document.getElementById("idTituloGraficoAcumulativo");
            $(tituloGraficoAcumulativo).addClass("bold");
            $scope.textoGraficoAcumulativo = true;
            var tituloGraficoComparativo = document.getElementById("idTituloGraficoComparativo");
            $(tituloGraficoComparativo).removeClass("bold");
            $scope.textoGraficoComparativo = false;
            if (chart != null)
                chart.destroy();
            chart = new ApexCharts(document.querySelector("#chart_div"), options);
            chart.render();
        };
        // Por hora nao e mais um grafico comparativo. E somente um grafico de acompanhamento
        $scope.abreGraficoComparativo = function () {
            $scope.idGraficoAberto = 2;
            var arrayUmAtual = [];
            var arrayDoisAtual = [];
            var arrayUmAnterior = [];
            var arrayDoisAnterior = [];
            var escolheuMesAtual = true;
            var arrayData = [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ];
            var dataAtual = new Date();
            if ($scope.anoFront == dataAtual.getFullYear() &&
                $scope.mesFront == meses[dataAtual.getMonth()]) {
                escolheuMesAtual = true;
            }
            else {
                escolheuMesAtual = false;
            }
            if ($scope.card.titulo === "Contatos Capturados") {
                arrayUmAtual = $scope.listaContatosCapturados;
                arrayUmAnterior = $scope.listaContatosCapturadosMesAnterior;
            }
            else if ($scope.card.titulo === "Contatos - E-mails Abertos") {
                arrayUmAtual = $scope.listaContatosEmailsAbertos;
                arrayUmAnterior = $scope.listaContatosEmailsAbertosAnterior;
            }
            else if ($scope.card.titulo === "Contatos Abordados") {
                arrayUmAtual = $scope.listaContatosAbordados;
                arrayUmAnterior = $scope.listaContatosAbordadosMesAnterior;
            }
            else if ($scope.card.titulo === "Alta Confiabilidade") {
                arrayUmAtual = $scope.listaConfiabilidadeAlta;
                arrayUmAnterior = $scope.listaConfiabilidadeAltaMesAnterior;
            }
            else if ($scope.card.titulo === "Média Confiabilidade") {
                arrayUmAtual = $scope.listaConfiabilidadeMedia;
                arrayUmAnterior = $scope.listaConfiabilidadeMediaMesAnterior;
            }
            else if ($scope.card.titulo === "Baixa Confiabilidade") {
                arrayUmAtual = $scope.listaConfiabilidadeBaixa;
                arrayUmAnterior = $scope.listaConfiabilidadeBaixaMesAnterior;
            }
            else if ($scope.card.titulo === "Sem Confiabilidade") {
                arrayUmAtual = $scope.listaConfiabilidadeSem;
                arrayUmAnterior = $scope.listaConfiabilidadeSemMesAnterior;
            }
            else if ($scope.card.titulo === "E-mails Enviados") {
                arrayUmAtual = $scope.listaEmailEnviado;
                arrayUmAnterior = $scope.listaEmailEnviadoMesAnterior;
            }
            else if ($scope.card.titulo === "E-mails Entregues") {
                arrayUmAtual = $scope.listaEmailEntregue;
                arrayUmAnterior = $scope.listaEmailEntregueMesAnterior;
            }
            else if ($scope.card.titulo === "E-mails Bounces") {
                arrayUmAtual = $scope.listaEmailBounce;
                arrayUmAnterior = $scope.listaEmailBounceMesAnterior;
            }
            else if ($scope.card.titulo === "E-mails Abertos") {
                arrayUmAtual = $scope.listaEmailAberto;
                arrayUmAnterior = $scope.listaEmailAbertoMesAnterior;
            }
            else if ($scope.card.titulo === "Respostas Positivas") {
                arrayUmAtual = $scope.listaRespostaPositiva;
                arrayUmAnterior = $scope.listaRespostaPositivaMesAnterior;
            }
            else if ($scope.card.titulo === "Respostas Neutras") {
                arrayUmAtual = $scope.listaRespostaNeutra;
                arrayUmAnterior = $scope.listaRespostaNeutraMesAnterior;
            }
            else if ($scope.card.titulo === "Respostas Negativas") {
                arrayUmAtual = $scope.listaRespostaNegativa;
                arrayUmAnterior = $scope.listaRespostaNegativaMesAnterior;
            }
            else if ($scope.card.titulo === "Total de Respostas") {
                arrayUmAtual = $scope.listaRespostaTotal;
                arrayUmAnterior = $scope.listaRespostaTotalMesAnterior;
            }
            else if ($scope.card.titulo === "Janela de Atenção") {
                arrayUmAtual = $scope.listaJanelaOportunidade;
                arrayUmAnterior = $scope.listaJanelaOportunidadeMesAnterior;
            }
            arrayUmAtual = arrayUmAtual.sort();
            arrayUmAnterior = arrayUmAnterior.sort();
            var contAuxAtual = 0;
            var contAuxAnterior = 0;
            for (var i = 1; i <= 31; i++) {
                var contDiasAtual = 0;
                var contDiasAnterior = 0;
                var contArrayMaior = 0;
                if (arrayUmAtual.length >= arrayUmAnterior.length)
                    contArrayMaior = arrayUmAtual.length;
                else
                    contArrayMaior = arrayUmAnterior.length;
                for (var j = 0; j <= contArrayMaior; j++) {
                    if (arrayUmAtual[j] == i) {
                        contDiasAtual++;
                    }
                    if (arrayUmAnterior[j] == i) {
                        contDiasAnterior++;
                    }
                }
                if (["Janeiro", "Agosto"].includes($scope.mesFront)) {
                    contAuxAtual = contDiasAtual;
                    // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                    arrayDoisAtual.push(contAuxAtual);
                    contAuxAnterior += contDiasAnterior;
                    arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                }
                else if (["Maio", "Julho", "Outubro", "Dezembro"].includes($scope.mesFront)) {
                    if (i == 31) {
                        contAuxAtual = contDiasAtual;
                        // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                        arrayDoisAtual.push(contAuxAtual);
                    }
                    else {
                        contAuxAtual = contDiasAtual;
                        // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                        arrayDoisAtual.push(contAuxAtual);
                        contAuxAnterior = contDiasAnterior;
                        arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                    }
                }
                else if (["Abril", "Junho", "Setembro", "Novembro"].includes($scope.mesFront)) {
                    if (i == 31) {
                        contAuxAnterior = contDiasAnterior;
                        arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        arrayData.pop();
                    }
                    else {
                        contAuxAtual = contDiasAtual;
                        // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                        arrayDoisAtual.push(contAuxAtual);
                        contAuxAnterior = contDiasAnterior;
                        arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                    }
                }
                else if (["Fevereiro"].includes($scope.mesFront)) {
                    // ano bisexto
                    if ($scope.anoFront % 4 == 0) {
                        if (i == 30 || i == 31) {
                            contAuxAnterior = contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                            arrayData.pop();
                        }
                        else {
                            contAuxAtual = contDiasAtual;
                            // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            arrayDoisAtual.push(contAuxAtual);
                            contAuxAnterior = contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                    }
                    else {
                        if (i == 29 || i == 30 || i == 31) {
                            contAuxAnterior = contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                            arrayData.pop();
                        }
                        else {
                            contAuxAtual = contDiasAtual;
                            // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            arrayDoisAtual.push(contAuxAtual);
                            contAuxAnterior = contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                    }
                }
                else if (["Março"].includes($scope.mesFront)) {
                    // ano bisexto
                    if ($scope.anoFront % 4 == 0) {
                        if (i == 30 || i == 31) {
                            contAuxAtual = contDiasAtual;
                            // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            arrayDoisAtual.push(contAuxAtual);
                        }
                        else {
                            contAuxAtual = contDiasAtual;
                            // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            arrayDoisAtual.push(contAuxAtual);
                            contAuxAnterior = contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                    }
                    else {
                        if ((i == 29) | (i == 30) | (i == 31)) {
                            contAuxAtual = contDiasAtual;
                            // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            arrayDoisAtual.push(contAuxAtual);
                        }
                        else {
                            contAuxAtual = contDiasAtual;
                            // arrayDoisAtual.push({ x: i, y: contAuxAtual });
                            arrayDoisAtual.push(contAuxAtual);
                            contAuxAnterior = contDiasAnterior;
                            arrayDoisAnterior.push({ x: i, y: contAuxAnterior });
                        }
                    }
                }
                // contAuxAtual = contDiasAtual;
                // contAuxAnterior = contDiasAnterior;
                // arrayDoisAnterior.push({ x: i, y: contAuxAnterior })
                // arrayDoisAtual.push({ x: i, y: contAuxAtual });
            }
            var options = {
                chart: {
                    type: "bar",
                    height: "230px",
                    width: "100%",
                    // stacked: true, // serve para empilhar uma barra na outra
                    toolbar: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: "Dados deste mês (" + $scope.mesFront + ")",
                        data: arrayDoisAtual,
                    },
                    // {
                    //     name: 'Comparativo com o mês anterior (' + $scope.mesAnteriorFront + ')',
                    //     data: arrayDoisAnterior
                    // }
                ],
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: arrayData,
                    labels: {
                        style: {
                            fontSize: "10px",
                            colors: "#adadad",
                        },
                        offsetY: -4,
                    },
                    tickPlacement: "on",
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                    min: 0,
                    tickAmount: 4,
                    show: false,
                    floating: false,
                    labels: {
                        style: {
                            fontSize: "10px",
                            color: "#adadad",
                        },
                        formatter: function (value, timestamp, index) {
                            if (value == undefined)
                                return undefined;
                            return value + "";
                        },
                    },
                },
                legend: {
                    position: "bottom",
                    showForSingleSeries: true,
                    horizontalAlign: "right",
                    offsetY: -10,
                    fontSize: "10px",
                    labels: {
                        colors: "#adadad",
                    },
                    markers: {
                        width: 8,
                        height: 8,
                        radius: 0,
                    },
                },
                grid: {
                    borderColor: "#c8c8c873",
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                colors: ["#96d7aa", "#9c9c9c"],
            };
            var tituloGraficoAcumulativo = document.getElementById("idTituloGraficoAcumulativo");
            $(tituloGraficoAcumulativo).removeClass("bold");
            $scope.textoGraficoAcumulativo = false;
            var tituloGraficoComparativo = document.getElementById("idTituloGraficoComparativo");
            $(tituloGraficoComparativo).addClass("bold");
            $scope.textoGraficoComparativo = true;
            if (chart != null)
                chart.destroy();
            chart = new ApexCharts(document.querySelector("#chart_div"), options);
            chart.render();
        };
        function agrupaArrayPorChave(array, chave) {
            if (array.length > 0) {
                return array.reduce(function (resultado, valorAtual) {
                    (resultado[valorAtual[chave]] =
                        resultado[valorAtual[chave]] || []).push(valorAtual);
                    return resultado;
                }, {});
            }
        }
        //#region MONTAGEM E SELEÇÃO DOS FILTROS
        function montarFiltroMeses() {
            var anoAtual = new Date().getFullYear();
            var mesAtual = new Date().getMonth();
            for (var j = anoAtual; j >= 2018; j--) {
                if (j == anoAtual) {
                    for (var i = mesAtual; i >= 0; i--) {
                        $scope.mesesFiltro.push({
                            id: "mes" + i + j,
                            data: meses[i] + " de " + j,
                            mes: i,
                            ano: j,
                        });
                    }
                }
                else {
                    for (i = 11; i >= 0; i--) {
                        $scope.mesesFiltro.push({
                            id: "mes" + i + j,
                            data: meses[i] + " de " + j,
                            mes: i,
                            ano: j,
                        });
                    }
                }
            }
            var mesCont = $scope.mesesFiltro[0].mes + 1;
            var mesAux = "" + mesCont;
            if (mesCont < 10)
                mesAux = "0" + mesCont;
            $scope.dataRanking =
                "'" + $scope.mesesFiltro[0].ano + "-" + mesAux + "-" + "01'";
        }
        $scope.selecionaMes = function (ano, mes, id, idFiltro) {
            var div, obj, tr, imgs, img;
            div = document.getElementById(idFiltro);
            tr = div.getElementsByTagName("tr");
            imgs = div.getElementsByTagName("img");
            $(tr).removeClass("option-checked");
            $(imgs).addClass("invisible");
            obj = document.getElementById(id);
            img = obj.getElementsByTagName("img");
            var mesFormatado = "01";
            if (mes + 1 < 10)
                mesFormatado = "0" + (mes + 1);
            else
                mesFormatado = mes + 1;
            if (!$(obj).hasClass("option-checked")) {
                $(obj).addClass("option-checked");
                $(img).removeClass("invisible");
                filtroMeses.splice(0, 1);
                filtroMeses.push({
                    _id: id,
                    ano: ano,
                    mes: mes,
                    data: "'" + ano + "-" + mesFormatado + "-" + "01'",
                });
            }
            $scope.anoFront = filtroMeses[0].ano;
            $scope.mesFront = meses[filtroMeses[0].mes];
            if ($scope.mesFront === "Janeiro")
                $scope.mesAnteriorFront = "Dezembro";
            else
                $scope.mesAnteriorFront = meses[filtroMeses[0].mes - 1];
            refresh();
            $scope.dataRanking = filtroMeses[0].data;
            if ($scope.montaCardsNaTelaPrimeiraVez) {
                setTimeout(function () {
                    montarCardsDaTela();
                }, 200);
                $scope.montaCardsNaTelaPrimeiraVez = false;
            }
            else {
                $scope.selecionaRanking($scope.atvRankingSelecionado);
            }
        };
        setTimeout(function () {
            $scope.montaCardsNaTelaPrimeiraVez = true;
            $scope.selecionaMes($scope.anoFront, mesInicial, idMesInicial, "filtrarMes");
        }, 1000);
        function montarFiltroFront() {
            $http({
                method: "GET",
                url: "/relatorio/montarFiltroFront",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (response) {
                for (var i = 0; i < response.data.oid.length; i++) {
                    $scope.usuariosTime.push({
                        id: "usuarios_id" + i,
                        nome: response.data.oid[i].nome,
                        sobrenome: response.data.oid[i].sobrenome,
                        objectIdLogin: response.data.oid[i].objectIdLogin,
                        status: response.data.oid[i].status,
                    });
                }
                for (var i = 0; i < response.data.cadencias.length; i++) {
                    $scope.cadenciasTime.push({
                        id: "cadencias_id" + i,
                        idCadencia: response.data.cadencias[i].idCadencia,
                        nomeCadencia: response.data.cadencias[i].nomeCadencia,
                    });
                }
                $scope.timeRanking = [];
                for (var i = 0; i < $scope.usuariosTime.length; i++) {
                    $scope.timeRanking.push({
                        _id: $scope.usuariosTime[i].id,
                        objectIdLogin: $scope.usuariosTime[i].objectIdLogin,
                    });
                }
                $scope.timeCadencia = [];
                for (var i = 0; i < $scope.cadenciasTime.length; i++) {
                    $scope.timeCadencia.push({
                        _id: $scope.cadenciasTime[i].id,
                        objectIdDisparo: $scope.cadenciasTime[i].objectIdDisparo,
                    });
                }
                setTimeout(function () {
                    $scope.selecionaRanking("COCA");
                    ordenaFiltroUsuarios();
                    ordenaFiltroCadencia();
                }, 1000);
                return $scope.usuariosTime;
            });
        }
        $scope.selecionaUsuarios = function (user, id) {
            if (user == "TODOS") {
                for (var i = 0; i < $scope.usuariosTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.usuariosTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if (!$(obj).hasClass("option-checked")) {
                        $(obj).addClass("option-checked");
                        $(img).removeClass("invisible");
                        filtroUsuarios.push({
                            _id: $scope.usuariosTime[i].id,
                            objectIdLogin: $scope.usuariosTime[i].objectIdLogin,
                        });
                        $scope.usuariosSelecionados += 1;
                    }
                }
                refresh();
            }
            else if (user == "DESMARCAR") {
                for (var i = 0; i < $scope.usuariosTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.usuariosTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if ($(obj).hasClass("option-checked")) {
                        $(obj).removeClass("option-checked");
                        $(img).addClass("invisible");
                        filtroUsuarios.splice(0, $scope.usuariosTime.length);
                        $scope.usuariosSelecionados -= 1;
                    }
                }
                refresh();
            }
            else {
                var obj, img;
                obj = document.getElementById(id);
                img = obj.getElementsByTagName("img");
                if (!$(obj).hasClass("option-checked")) {
                    $(obj).addClass("option-checked");
                    $(img).removeClass("invisible");
                    filtroUsuarios.push({
                        _id: id,
                        objectIdLogin: user,
                    });
                    $scope.usuariosSelecionados += 1;
                    refresh();
                }
                else {
                    var index;
                    for (i = 0; i < filtroUsuarios.length; i++) {
                        if (filtroUsuarios[i]._id === id) {
                            index = i;
                            filtroUsuarios.splice(index, 1);
                            $(obj).removeClass("option-checked");
                            $(img).addClass("invisible");
                            $scope.usuariosSelecionados -= 1;
                            refresh();
                            return "usuario retirado";
                        }
                    }
                }
            }
        };
        $scope.selecionaCadencias = function (cad, id) {
            if (cad == "TODOS") {
                for (var i = 0; i < $scope.cadenciasTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.cadenciasTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if (!$(obj).hasClass("option-checked")) {
                        $(obj).addClass("option-checked");
                        $(img).removeClass("invisible");
                        filtroCadencias.push({
                            _id: $scope.cadenciasTime[i].id,
                            objectIdDisparo: $scope.cadenciasTime[i].idCadencia,
                        });
                        $scope.cadenciasSelecionadas += 1;
                    }
                }
                refresh();
                $scope.selecionaRanking($scope.atvRankingSelecionado);
            }
            else if (cad == "DESMARCAR") {
                for (var i = 0; i < $scope.cadenciasTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.cadenciasTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if ($(obj).hasClass("option-checked")) {
                        $(obj).removeClass("option-checked");
                        $(img).addClass("invisible");
                        filtroCadencias.splice(0, $scope.cadenciasTime.length);
                        $scope.cadenciasSelecionadas -= 1;
                    }
                }
                refresh();
                $scope.selecionaRanking($scope.atvRankingSelecionado);
            }
            else {
                var obj, img;
                obj = document.getElementById(id);
                img = obj.getElementsByTagName("img");
                if (!$(obj).hasClass("option-checked")) {
                    $(obj).addClass("option-checked");
                    $(img).removeClass("invisible");
                    filtroCadencias.push({
                        _id: id,
                        objectIdDisparo: cad,
                    });
                    $scope.cadenciasSelecionadas += 1;
                    refresh();
                    $scope.selecionaRanking($scope.atvRankingSelecionado);
                }
                else {
                    var index;
                    for (i = 0; i < filtroCadencias.length; i++) {
                        if (filtroCadencias[i]._id === id) {
                            index = i;
                            filtroCadencias.splice(index, 1);
                            $(obj).removeClass("option-checked");
                            $(img).addClass("invisible");
                            $scope.cadenciasSelecionadas -= 1;
                            refresh();
                            $scope.selecionaRanking($scope.atvRankingSelecionado);
                            return "cadencia retirada";
                        }
                    }
                }
            }
        };
        function montaData(date) {
            date = new Date(date);
            var dataMontada = {};
            var ano = new Date(date).getUTCFullYear();
            var mes = new Date(date).getUTCMonth() + 1;
            switch (mes) {
                case 12:
                    dataMontada.inicial = ano + "-" + (mes - 1) + "-01";
                    dataMontada.final = ano + 1 + "-0" + 1 + "-01";
                    break;
                case 11:
                    dataMontada.inicial = ano + "-" + (mes - 1) + "-01";
                    dataMontada.final = ano + "-" + (mes + 1) + "-01";
                    break;
                case 10:
                    dataMontada.inicial = ano + "-0" + (mes - 1) + "-01";
                    dataMontada.final = ano + "-" + (mes + 1) + "-01";
                    break;
                case 9:
                    dataMontada.inicial = ano + "-0" + (mes - 1) + "-01";
                    dataMontada.final = ano + "-" + (mes + 1) + "-01";
                    break;
                case 1:
                    dataMontada.inicial = ano - 1 + "-12" + "-01";
                    dataMontada.final = ano + "-" + (mes + 1) + "-01";
                    break;
                default:
                    dataMontada.inicial = ano + "-0" + (mes - 1) + "-01";
                    dataMontada.final = ano + "-0" + (mes + 1) + "-01";
                    break;
            }
            dataMontada.inicial = new Date(dataMontada.inicial);
            dataMontada.final = new Date(dataMontada.final);
            return dataMontada;
        }
        function montarCardsDaTela() {
            $http({
                method: "GET",
                url: "/relatorio/consultarListaCards",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (response) {
                if (response !== null && response.data.length > 0) {
                    for (var i = 0; i < response.data[0].listaCards.length; i++) {
                        $scope.adicionaCard(response.data[0].listaCards[i], false);
                    }
                }
                else {
                    var cardsIniciais = ["COCA", "EMEN", "EMET", "EMBO", "TORE", "REPO"];
                    for (var i = 0; i < cardsIniciais.length; i++) {
                        $scope.adicionaCard(cardsIniciais[i], false);
                    }
                }
            });
        }
        $scope.salvarCardsTela = function () {
            var cardsOrdenados = $scope.cards.sort(function (x, y) {
                if (x.order.order > y.order.order)
                    return 1;
                else
                    return -1;
            });
            var listaCodigosCards = [];
            for (var i = 0; i < cardsOrdenados.length; i++) {
                listaCodigosCards.push(cardsOrdenados[i].code);
            }
            $http({
                method: "POST",
                url: "/relatorio/salvarListaCards",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    listaCards: listaCodigosCards,
                },
            }).then(function (response) {
                // console.log(response);
            });
        };
        $scope.rankingLoader = true;
        $scope.atividadeRanking = "Contatos capturados";
        $scope.atvRankingSelecionado = "COCA";
        montarFiltroFront();
        montarFiltroMeses();
        mixpanel.track("Relatório | Visão geral das atividades", {
            Local_IP: $rootScope.ip,
        });
        //#endregion
    },
]);
