angular.module("ramperv3").service("MonsterCreditosPagamentosService", [
    "$rootScope",
    "$http",
    function ($rootScope, $http) {
        /**
         * Deve consumir a api para listar os créditos disponiveis
         */
        function obterCreditosDisponiveis() {
            var userId = $rootScope.DadosCadastrais ? $rootScope.DadosCadastrais.objectIdLogin : $rootScope.permissoes.userId;
            return $http({
                method: "GET",
                url: "/v2/usuario/".concat(userId, "/creditos-disponiveis"),
            });
        }
        /**
         * Deve consumir a api para listar as faturas do usuário
         */
        function listarFaturas() {
            return $http({
                method: "GET",
                url: "/credito/consultarFaturas",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            });
        }
        /**
         * Deve consumir a api para listar histórico de créditos
         */
        function listarHistoricoCreditos() {
            return $http({
                method: "GET",
                url: "/v2/usuario/".concat($rootScope.permissoes.userId, "/historico-creditos"),
            });
        }
        /**
         * Deve consumir a api para baixar o boleto de uma fatura pedente
         */
        function gerarBoleto(mes) {
            mixpanel.track("CREDITOS E PAGAMENTOS | Gerou boleto", { Local_IP: $rootScope.ip });
            return $http({
                method: "GET",
                url: "credito/gerar-boletos/" + mes,
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            });
        }
        /**
         * Deve consumir a api para solicitar crédito
         */
        function solicitarCredito() {
            var data = {
                usuario: "".concat($rootScope.Login.nome, " ").concat($rootScope.Login.sobrenome, " (").concat($rootScope.Login.email, ")"),
                empresa: $rootScope.empresaUsuario,
                responsavel: $rootScope.CsResponsavel,
            };
            mixpanel.track("CREDITOS E PAGAMENTOS | Solicitou créditos", { Local_IP: $rootScope.ip });
            return $http({
                method: "POST",
                url: "/v2/usuario/solicitar-credito",
                data: data,
            });
        }
        return { obterCreditosDisponiveis: obterCreditosDisponiveis, listarHistoricoCreditos: listarHistoricoCreditos, listarFaturas: listarFaturas, gerarBoleto: gerarBoleto, solicitarCredito: solicitarCredito };
    },
]);
