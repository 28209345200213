var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
angular.module("ramperv3").component("monsterContatosEnriquecimento", {
    templateUrl: "views/monsterContatoEnriquecimentoIA.html",
    bindings: { contatoId: "<", linkedin: "<", perfilEmpresarialConfig: "<", tela: "<" },
    transclude: true,
    controller: [
        "$rootScope",
        "$scope",
        "$sce",
        "MonsterContatosService",
        "MonsterToasterService",
        "MonsterIaService",
        "EventSourceFactory",
        function ($rootScope, $scope, $sce, MonsterContatosService, MonsterToasterService, MonsterIaService, EventSourceFactory) {
            var _this = this;
            var markdownit = window.markdownit();
            /**
             * Estado inicial da página $scope
             */
            $scope.renderedMarkdown = null;
            $scope.botaoEnriquecimentoLoading = false;
            $scope.bucket = $rootScope.bucket;
            $scope.contatoId = null;
            $scope.$watch("$ctrl.linkedin", function (linkedin) {
                $scope.linkedin = linkedin ? linkedin : null;
            });
            $scope.$watch("$ctrl.perfilEmpresarialConfig", function (perfilEmpresarialConfig) {
                $scope.perfilEmpresarialConfig = perfilEmpresarialConfig ? perfilEmpresarialConfig : null;
            });
            /**
             * Watch para atributos do componente
             */
            $scope.$watch("$ctrl.contatoId", function (contatoId) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            $scope.contatoId = contatoId ? contatoId : null;
                            if (!contatoId) return [3 /*break*/, 2];
                            return [4 /*yield*/, MonsterIaService.hasContactInEnrichmentCache(contatoId)];
                        case 1:
                            if (!!(_a.sent())) {
                                $scope.botaoEnriquecimentoLoading = true;
                                return [2 /*return*/];
                            }
                            else {
                                $rootScope.monsterShowLoading = true;
                            }
                            MonsterContatosService.getEnriquecimento(contatoId).then(function (result) {
                                $rootScope.monsterShowLoading = false;
                                $scope.renderedMarkdown = null;
                                if (result.data && result.data.enriquecimento) {
                                    $scope.renderedMarkdown = $sce.trustAsHtml(markdownit.render(result.data.enriquecimento));
                                    return;
                                }
                            }, function () {
                                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao listar dados de enrequecimento por Ia. Tente novamente mais tarde.", 6000);
                                $rootScope.monsterShowLoading = false;
                                $scope.renderedMarkdown = null;
                            });
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
            /**
             * Deve chamar o serviço para solicitar o enriquecimento do contato
             * @param contatoId identificador do contato
             */
            $scope.enriquecerDados = function (contatoId) {
                $scope.botaoEnriquecimentoLoading = true;
                if ($scope.$ctrl.tela === "contato") {
                    mixpanel.track("IA | Enriqueceu contato na lista de contatos", {
                        Local_IP: $rootScope.ip,
                    });
                }
                if ($scope.$ctrl.tela === "conexao") {
                    mixpanel.track("IA | Enriqueceu contato na Janela de Atenção", {
                        Local_IP: $rootScope.ip,
                    });
                }
                MonsterIaService.enrichContactData(contatoId).then(function () { }, function (err) {
                    var _a, _b;
                    if ((_b = (_a = err.data) === null || _a === void 0 ? void 0 : _a.errorCode) === null || _b === void 0 ? void 0 : _b.includes("insufficient-balance")) {
                        MonsterToasterService.showErrorToaster("", "Você não possui créditos suficientes. Recarregue para continuar utilizando esta funcionalidade.", 6000);
                    }
                    else {
                        MonsterToasterService.showErrorToaster("", "Não foi possível enriquecer os dados do contato.", 6000);
                    }
                    $scope.botaoEnriquecimentoLoading = false;
                });
            };
            /**
             * Evento disparado quando o controlador for destruído
             */
            $scope.$on("$destroy", function () {
                $rootScope.botaoEnriquecimentoLoading = false;
                MonsterToasterService.removeToaster();
            });
            /**
             * Ouvinte para sincronizar tela quando a ia finalizar o enriquecimento
             */
            EventSourceFactory.addEventListener("sync-contact-enrichment-ia", function (event) {
                $rootScope.botaoEnriquecimentoLoading = false;
                $scope.renderedMarkdown = $sce.trustAsHtml(markdownit.render(JSON.parse(event.data).insight));
                MonsterIaService.deleteContactInEnrichmentCache(JSON.parse(event.data).contactId);
            });
        },
    ],
});
