angular.module("ramperv3").service("MonsterToasterService", [
    "$rootScope",
    "$timeout",
    "$compile",
    "$document",
    function ($rootScope, $timeout, $compile, $document) {
        var compiledElement = null;
        var timeoutPromise = null;
        $rootScope.monsterRemoveToaster = function () {
            removeToaster();
        };
        function showToaster(type, message, description, timeout, width) {
            var action = "\n        <button class=\"monster-toaster-close-button\" ng-click=\"monsterRemoveToaster()\">\n          <ms-icon name=\"CloseOutlined\" size=\"12\"></ms-icon>\n        </button>\n      ";
            var toasterElement = angular.element("\n          <div class=\"monster-toaster-container\">\n            <ms-alert style=\"width: ".concat(width || 770, "px\" type=\"").concat(type, "\" message=\"").concat(message, "\" description=\"").concat(description, "\"></ms-alert>\n            ").concat(action, "\n          </div>\n        "));
            var bodyElement = angular.element(document.getElementById("dvBlur"));
            compiledElement = $compile(toasterElement)($rootScope);
            bodyElement.prepend(compiledElement);
            if (timeout) {
                timeoutPromise = $timeout(function () {
                    removeToaster();
                }, timeout || 2000);
            }
        }
        /**
         * Deve remover o toaster caso o toaster tinha sido adicionado
         */
        function removeToaster() {
            if (compiledElement) {
                compiledElement.remove();
            }
            if (timeoutPromise) {
                $timeout.cancel(timeoutPromise);
                timeoutPromise = null;
            }
        }
        /**
         * Deve apresentar uma mensagem do tipo 'info' para o usuário
         * @param message mensagem a ser apresentado
         * @param description  descrição da mensagem
         * @param timeout tempo que a mensagem deve ficar em tela
         */
        function showInfoToaster(message, description, timeout, width) {
            removeToaster();
            showToaster("info", message, description, timeout, width);
        }
        /**
         * Deve apresentar uma mensagem do tipo 'success' para o usuário
         * @param message mensagem a ser apresentado
         * @param description  descrição da mensagem
         * @param timeout tempo que a mensagem deve ficar em tela
         */
        function showSuccessToaster(message, description, timeout, width) {
            removeToaster();
            showToaster("success", message, description, timeout, width);
        }
        /**
         * Deve apresentar uma mensagem do tipo 'warning' para o usuário
         * @param message mensagem a ser apresentado
         * @param description  descrição da mensagem
         * @param timeout tempo que a mensagem deve ficar em tela
         */
        function showWarningToaster(message, description, timeout, width) {
            removeToaster();
            showToaster("warning", message, description, timeout, width);
        }
        /**
         * Deve apresentar uma mensagem do tipo 'error' para o usuário
         * @param message mensagem a ser apresentado
         * @param description  descrição da mensagem
         * @param timeout tempo que a mensagem deve ficar em tela
         */
        function showErrorToaster(message, description, timeout, width) {
            removeToaster();
            showToaster("error", message, description, timeout, width);
        }
        return {
            removeToaster: removeToaster,
            showInfoToaster: showInfoToaster,
            showSuccessToaster: showSuccessToaster,
            showWarningToaster: showWarningToaster,
            showErrorToaster: showErrorToaster,
        };
    },
]);
