angular.module("ramperv3").service("MonsterIndexedDbCacheService", ["$q", "$timeout", function ($q, $timeout) {
        var db;
        var dbName = "V3CacheDB";
        var storeName = "cache";
        var cacheDuration = 1 * 60 * 1000;
        var openDB = function () {
            var deferred = $q.defer();
            if (db) {
                deferred.resolve(db);
                return deferred.promise;
            }
            var request = indexedDB.open(dbName, 1);
            request.onupgradeneeded = function (event) {
                db = event.target.result;
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName, { keyPath: "id" });
                }
            };
            request.onsuccess = function (event) {
                db = event.target.result;
                deferred.resolve(db);
            };
            request.onerror = function (event) {
                console.error("Erro ao abrir IndexedDB:", event.target.errorCode);
                deferred.reject("Erro ao abrir IndexedDB: " + event.target.errorCode);
            };
            return deferred.promise;
        };
        var addContact = function (contactId) {
            return openDB().then(function (dbInstance) {
                var deferred = $q.defer();
                var transaction = dbInstance.transaction([storeName], "readwrite");
                var store = transaction.objectStore(storeName);
                var contact = {
                    id: contactId,
                    date: new Date().toISOString()
                };
                var request = store.add(contact);
                request.onsuccess = function () {
                    deferred.resolve("Contato salvo em cache.");
                };
                request.onerror = function (event) {
                    deferred.reject("Erro ao salvar contato: " + event.target.errorCode);
                };
                return deferred.promise;
            });
        };
        var getAllContacts = function () {
            return openDB().then(function (dbInstance) {
                var deferred = $q.defer();
                var transaction = dbInstance.transaction([storeName], "readonly");
                var store = transaction.objectStore(storeName);
                var request = store.getAll();
                request.onsuccess = function (event) {
                    deferred.resolve(event.target.result);
                };
                request.onerror = function (event) {
                    deferred.reject("Erro ao obter contatos: " + event.target.errorCode);
                };
                return deferred.promise;
            });
        };
        var getContact = function (contactId) {
            return openDB().then(function (dbInstance) {
                var deferred = $q.defer();
                var transaction = dbInstance.transaction([storeName], "readonly");
                var store = transaction.objectStore(storeName);
                var request = store.get(contactId);
                request.onsuccess = function (event) {
                    deferred.resolve(event.target.result);
                };
                request.onerror = function (event) {
                    deferred.reject("Erro ao buscar contato: " + event.target.errorCode);
                };
                return deferred.promise;
            });
        };
        var removeContact = function (contactId) {
            return openDB().then(function (dbInstance) {
                var deferred = $q.defer();
                var transaction = dbInstance.transaction([storeName], "readwrite");
                var store = transaction.objectStore(storeName);
                var request = store.delete(contactId);
                request.onsuccess = function () {
                    deferred.resolve("Contato removido do cache.");
                };
                request.onerror = function (event) {
                    deferred.reject("Erro ao remover contato: " + event.target.errorCode);
                };
                return deferred.promise;
            });
        };
        var scheduleRemovals = function () {
            getAllContacts().then(function (contacts) {
                var now = new Date().getTime();
                contacts.forEach(function (contact) {
                    var contactTime = new Date(contact.date).getTime();
                    var timeDiff = now - contactTime;
                    if (timeDiff >= cacheDuration) {
                        removeContact(contact.id);
                    }
                    else {
                        $timeout(function () {
                            removeContact(contact.id);
                        }, cacheDuration - timeDiff);
                    }
                });
            });
        };
        scheduleRemovals();
        return {
            addContact: addContact,
            getAllContacts: getAllContacts,
            getContact: getContact,
            removeContact: removeContact
        };
    }]);
