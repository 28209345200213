angular.module("ramperv3").service("MonsterContatosService", [
    "$http",
    function ($http) {
        /**
         * Deve consumir a api para buscar dados de enriquecimento por IA
         */
        function getEnriquecimento(contatoId) {
            return $http({ method: "GET", url: "/v2/contatos/".concat(contatoId, "/enriquecimento") });
        }
        return { getEnriquecimento: getEnriquecimento };
    },
]);
