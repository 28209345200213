angular.module("ramperv3").controller("MonsterCreditosPagamentosController", [
    "$rootScope",
    "$scope",
    "$filter",
    "MonsterCreditosPagamentosService",
    "MonsterToasterService",
    function ($rootScope, $scope, $filter, MonsterCreditosPagamentosService, MonsterToasterService) {
        /**
         * Google Analytics
         */
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.monsterShowLoading = true;
        $rootScope.mostrarMenu = true;
        setTimeout(function () {
            $rootScope.monsterShowLoading = false;
            $rootScope.$apply();
        }, 1000);
        /**
         * Estado inicial da página $scope
         */
        $scope.permissoes = $rootScope.permissoes;
        $scope.historicoCreditos = [];
        $scope.faturas = [];
        /**
         * Deve consumir o serviço para oter os histórico de créditos
         */
        $scope.listarHistoricoCreditos = function () {
            $rootScope.monsterShowLoading = true;
            MonsterCreditosPagamentosService.listarHistoricoCreditos().then(function (result) {
                $scope.historicoCreditos = result.data;
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao listar hist\u00F3rico de cr\u00E9ditos. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve consumir o serviço para oter créditos disponiveis
         */
        $scope.listarFaturas = function () {
            $rootScope.monsterShowLoading = true;
            MonsterCreditosPagamentosService.listarFaturas().then(function (result) {
                $scope.faturas = result.data;
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao listar faturas. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve consumir o serviço para baixar boleto de uma fatura disponivel
         */
        $scope.onGerarBoleto = function (dataVencimento) {
            $rootScope.monsterShowLoading = true;
            var mes = dataVencimento.split("/")[1];
            MonsterCreditosPagamentosService.gerarBoleto(mes).then(function (result) {
                $rootScope.monsterShowLoading = false;
                window.open(result.data.message);
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao baixar boleto. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        if ($scope.permissoes.creditoPagamentos.creditHistory) {
            $scope.listarHistoricoCreditos();
        }
        if ($scope.permissoes.creditoPagamentos.paymentHistory) {
            $scope.listarFaturas();
        }
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
            MonsterToasterService.removeToaster();
        });
    },
]);
