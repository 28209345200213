angular.module("ramperv3").controller("SsoController", [
    "$rootScope",
    "$scope",
    "$http",
    "$location",
    "AcessosService",
    "AgenciaService",
    "MovideskService",
    function ($rootScope, $scope, $http, $location, AcessosService, AgenciaService, MovideskService) {
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        var token = $location.search().token;
        function ssoLogin() {
            $scope.authErro = false;
            $scope.loaderAtivo = true;
            $http({
                method: "POST",
                url: "/login/sso",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": "0881d3992f",
                },
                data: {
                    token: token,
                },
            }).then(function (response) {
                var _login = response.data.login;
                loginRamper(response, _login);
            }, function (error) {
                $scope.loaderAtivo = false;
                $scope.authErro = true;
                var erro = error.data.message.description;
                $scope.errorMessage = mapMensagensErro[erro] || mensagemErroPadrao;
            });
        }
        var mapMensagensErro = {
            "limite time": {
                titulo: "Olá,",
                paragrafos: [
                    "Infelizmente não conseguimos criar a conta pois a sua empresa já atingiu o limite de usuários contratados.",
                    "O administrador da conta já foi informado via e-mail e deverá entrar em contato com o CS da conta para adquirir mais usuários.",
                ],
            },
        };
        var mensagemErroPadrao = {
            titulo: "Olá,",
            paragrafos: ["Ocorreu um erro na autenticação do seu usuário."],
        };
        var _urlAcesso = document.URL.substr(7, 6);
        //   var _cookieUsuarioRamper = "cookieSRamper";
        //   apagarCookie(_cookieUsuarioRamper);
        $rootScope.mostrarTrocarConta = false;
        $scope.administrativoOuVisao = false;
        $rootScope.showBrowserLogins = [];
        $rootScope.loginRamperSenha = "";
        var _integracaoCRMLogin = "";
        var _integracaoPreVendaLogin = "";
        var _integracaoInboundLogin = "";
        $rootScope.CsResponsavel = null;
        $rootScope.codigoSuperlogica = "";
        $rootScope.semConfEmail = false;
        $rootScope.alertaConfEmail = false;
        $rootScope.temDireito = false;
        $("#dvNotificacao").hide();
        window.movideskChatWidget.show();
        $scope.consultarCsResponsavel = function (pIDCrypto) {
            $http({
                method: "GET",
                url: "/agencias/consultarCsResponsavel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": pIDCrypto,
                },
                params: {
                    objectIdTime: $rootScope.TimeUsuario.objectIdTime,
                },
            }).then(function (rConsultarCsResponsavel) {
                $http({
                    method: "GET",
                    url: "/logins/consultarLoginPorId",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": pIDCrypto,
                    },
                    params: {
                        objectIdLogin: rConsultarCsResponsavel.data
                            ? rConsultarCsResponsavel.data.objectIdLogin
                            : null,
                    },
                }).then(function (rConsultarLogin) {
                    $rootScope.CsResponsavel = rConsultarLogin.data
                        ? rConsultarLogin.data.email
                        : "";
                    if ($rootScope.loginRamperSenha
                        ? !$rootScope.loginRamperSenha.login
                        : true) {
                        $productFruits.push(['init', 'XcqFZfBKmERhjgEd', 'pt', {
                                username: $rootScope.Login.email,
                                email: $rootScope.Login.email,
                                firstname: $rootScope.Login.nome,
                                lastname: $rootScope.Login.sobrenome,
                                props: {
                                    Plano: $rootScope.Plano,
                                    Superlogica: $rootScope.codigoSuperlogica,
                                    Empresa: $rootScope.empresaLogado,
                                }
                            }]);
                    }
                });
            });
        };
        $scope.consultarCreditos = function (pIDCrypto, pLogin, pDados) {
            $rootScope.creditosInicio = null;
            $http({
                method: "GET",
                url: "/v2/usuario/".concat(pDados.objectIdLogin, "/creditos-disponiveis")
            }).then(function (rConsultarCreditos) {
                $http({
                    method: "GET",
                    url: "/login/consultarContatosMixpanel",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                }).then(function (rConsultarContatosMixpanel) {
                    $rootScope.creditosInicio = rConsultarCreditos.data;
                    $rootScope.semCreditos = $rootScope.creditosInicio.creditosLegadoDisponiveis + $rootScope.creditosInicio.creditosDisponiveis <= 0;
                    if (_urlAcesso != "localh") {
                        identificarIP(function (rIP) {
                            $rootScope.ip = rIP;
                            mixpanel.identify(pIDCrypto);
                            mixpanel.people.set({
                                $email: pLogin.login.email,
                                $name: pDados.nome,
                                $created: pLogin.login.dataContratacao,
                                Status: pLogin.login.status,
                                TipoUsuario: pLogin.login.tipo,
                                Envios: $rootScope.creditosInicio.creditosEnvios,
                                EnviosDisponiveis: $rootScope.creditosInicio.creditosEnviosDisponiveis,
                                CreditoLegado: $rootScope.creditosInicio.creditosLegado,
                                CreditoLegadoDisponiveis: $rootScope.creditosInicio.creditosLegadosDisponiveis,
                                Credito: $rootScope.creditosInicio.creditos,
                                CreditoDisponiveis: $rootScope.creditosInicio.creditosDisponiveis,
                                Versao: "V3",
                                CRM: _integracaoCRMLogin,
                                "Pre Vendas": _integracaoPreVendaLogin,
                                Inbound: _integracaoInboundLogin,
                                Inadimplente: $rootScope.usuarioInadimplente,
                                "Dias inadimplente": $rootScope.diasUsuarioEstaInadimplente,
                                SuperLogica: pLogin.login.codigoEmpresaSuperlogica,
                                "Cs Responsável": $rootScope.CsResponsavel,
                                UltimoLogin: pLogin.login.dataUltimoLogin,
                                UltimaCaptura: pDados.ultimosRegistros.captura,
                                UltimoDisparo: pDados.ultimosRegistros.disparo,
                                UltimaResposta: pDados.ultimosRegistros.resposta,
                                UltimaCadenciaCriada: pDados.ultimosRegistros.cadencia,
                                QuantidadeCadenciaAtiva: pDados.ultimosRegistros.qtdCadenciaAtiva,
                                Plano: $rootScope.Plano,
                            });
                            if ($rootScope.Login.beta) {
                                beta = $rootScope.Login.beta.filter(function (obj) {
                                    return obj.tela == "Dashboard";
                                })[0];
                                if (beta && beta.usuarioBeta) {
                                    mixpanel.people.set_once("Beta Dashboard", true);
                                }
                            }
                            else {
                                mixpanel.people.set_once("Beta Dashboard", false);
                            }
                            mixpanel.track("Login | Fez login", { Local_IP: $rootScope.ip }, { Local_IP: $rootScope.ip });
                            if ($scope.emailRamper != null &&
                                $scope.emailRamper != "undefined" &&
                                $scope.emailRamper != undefined) {
                                mixpanel.track("Login Senha Ramper | Fez login Senha Ramper", {
                                    Local_IP: $rootScope.ip,
                                    "Usuario Ramper": $scope.emailRamper,
                                });
                                if (rConsultarContatosMixpanel.data) {
                                    rConsultarContatosMixpanel.data = false;
                                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                                }
                            }
                        });
                    }
                });
            });
        };
        var loginRamper = function (response, _login) {
            $rootScope.ConfiguracoesEmail =
                response.data.dados.aliasconfiguracoesemails[0];
            $("#logoRamperLogin").removeClass("loginUsuarioRamper");
            var _dados = response.data.dados;
            $rootScope.usuarioRamper =
                _login.login.email.indexOf("@ramper") > -1 ||
                    _urlAcesso.indexOf("go.ramper") <= -1;
            $rootScope.programaIndicacoes =
                _login.login.email == "hugo.oliveira@ramper.com.br" ||
                    _login.login.email == "amanda.passamani@superlogica.com" ||
                    _login.login.email == "bruno.scaravelli@mega.com.br" ||
                    _login.login.email == "ana.machado@superlogica.com" ||
                    _login.login.email == "everton.braga@traycorp.com.br" ||
                    _login.login.email == "giovanni.scaravelli@mega.com.br" ||
                    _login.login.email == "lidia.theophilo@mega.com.br" ||
                    _login.login.email == "wilson.junior@traycorp.com.br" ||
                    _login.login.email == "alef.zati@mega.com.br" ||
                    _login.login.email == "larissa.arruda@mega.com.br" ||
                    _login.login.email == "sabrina.oliveira@superlogica.com" ||
                    _login.login.email == "vanessa.placona@traycorp.com.br" ||
                    _login.login.email == "mirian.grilo@traycorp.com.br" ||
                    _login.login.email == "carloseduardo@cestaincentivo.com.br" ||
                    _login.login.email == "suely@cestaincentivo.com.br" ||
                    _login.login.email == "jeferson.santos@cestaincentivo.com.br" ||
                    _login.login.email == "daiana@cestaincentivo.com.br" ||
                    _login.login.email == "adriana@cestaincentivo.com.br" ||
                    _login.login.email == "fabiana@cestaincentivo.com.br" ||
                    _login.login.email == "rose@cestaincentivo.com.br" ||
                    _login.login.email == "marcelo.mello@synergie.com.br" ||
                    _login.login.email == "marcio.melo@synergie.com.br" ||
                    _login.login.email == "luciana@v2integradora.com.br" ||
                    _login.login.email == "william.batista@mega.com.br" ||
                    _login.login.email == "cintia.tonoli@superlogica.com" ||
                    _login.login.email == "giovanna.vallin@superlogica.com" ||
                    _login.login.email == "leonardo.pinheiro@mega.com.br" ||
                    _login.login.email == "bruna.gallo@mega.com.br" ||
                    _login.login.email == "lais.goncalez@mega.com.br" ||
                    _login.login.email == "monica.marcelino@mega.com.br" ||
                    _login.login.email == "lucio.biasini@mega.com.br" ||
                    _login.login.email == "gilmara.pereira@mega.com.br" ||
                    _login.login.email == "leticia.santos@mega.com.br" ||
                    _login.login.email == "daniela.moraes@mega.com.br" ||
                    _login.login.email == "lucas.monteiro@mega.com.br" ||
                    _login.login.email == "vinicius.trujillo@cabify.com" ||
                    _login.login.email == "vanessa.mattos@traycorp.com.br" ||
                    _login.login.email == "mkt@zenvia.com" ||
                    _login.login.email == "jeyciane.taimara@zenvia.com" ||
                    _login.login.email == "christiane.yumi@zenvia.com" ||
                    _login.login.email == "rogerio@cestaincentivo.com.br" ||
                    _login.login.email == "nataly.nunes@traycorp.com.br" ||
                    _login.login.email == "marilia.losito@traycorp.com.br" ||
                    _login.login.email == "fernando.roberti@traycorp.com.br" ||
                    _login.login.email == "negocios.ge@zenvia.com" ||
                    _login.login.email == "wmoreira@bwg.com.br" ||
                    _login.login.email == "correa@gruposicuressa.com.br" ||
                    _login.login.email == "apaschoal@bwg.com.br" ||
                    _login.login.email == "mbrancacci@bwg.com.br" ||
                    _login.login.email == "erick.santos@traycorp.com.br" ||
                    _login.login.email == "jraio@bwg.com.br" ||
                    _login.login.email == "caroline.santos@cabify.com" ||
                    _login.login.email == "mariane.santos@cabify.com" ||
                    _login.login.email == "bruna.estaropolis@mega.com.br" ||
                    _login.login.email == "gabriel.leite@mega.com.br" ||
                    _login.login.email == "eduardo.pereira@traycorp.com.br";
            $rootScope.Login = _login.login;
            $rootScope.creditosRenovados =
                new Date($rootScope.Login.dataProximaRenovacao).toDateString() ==
                    new Date().toDateString();
            $rootScope.IDCrypto = _login._idcrypto;
            $rootScope.Tipo = _login.login.tipo;
            $rootScope.ConfiguracoesEmail = _login.login.configuracoesemails[0];
            $rootScope.TimeUsuario = _login.login.timeusuarios;
            $rootScope.Plano = _login.login.times[0].plano;
            $rootScope.codigoSuperlogica = _login.login.times[0]
                .codigoEmpresaSuperlogica
                ? _login.login.times[0].codigoEmpresaSuperlogica
                : "";
            if (!$rootScope.ConfiguracoesEmail ||
                !$rootScope.ConfiguracoesEmail.servidor) {
                $rootScope.semConfEmail = true;
                $rootScope.alertaConfEmail = true;
            }
            try {
                $rootScope.usuarioInadimplente = _login.login.inadimplente.status;
            }
            catch (e) {
                $rootScope.usuarioInadimplente = false;
            }
            try {
                $rootScope.diasUsuarioEstaInadimplente =
                    $rootScope.Login.inadimplente.diasInadimplente;
            }
            catch (e) {
                $rootScope.diasUsuarioEstaInadimplente = "";
            }
            AcessosService.setAcessos(response.data.login.login.times[0]);
            var possuiConversacao = response.data.login.login.times[0].possuiConversacao;
            var expiracaoTrialConversacao = response.data.login.login.times[0].expiracaoTrialConversacao;
            var possuiConexao = response.data.login.login.times[0].possuiConexao;
            if (possuiConversacao && possuiConversacao === true) {
                $rootScope.possuiConversacao = true;
            }
            else {
                if (expiracaoTrialConversacao && possuiConexao === true) {
                    $rootScope.possuiConversacao = true;
                }
                else {
                    $rootScope.possuiConversacao = false;
                }
            }
            $rootScope.temDireitoChat =
                $rootScope.possuiConversacao || $rootScope.Plano == "P";
            if ($rootScope.Plano == "B") {
                $rootScope.temDireitoIntegracao = false;
                $rootScope.temDireitoConexao = false;
            }
            else {
                switch ($rootScope.Plano) {
                    case "P":
                        $rootScope.temDireitoConexao = true;
                        $rootScope.temDireitoIntegracao = true;
                        setTimeout(function () {
                            $rootScope.temDireitoPlano();
                        }, 1000);
                        $rootScope.temDireitoPlano = function () {
                            $rootScope.temDireito = $rootScope.temDireitoIntegracao;
                        };
                        break;
                }
            }
            $productFruits.push(['init', 'XcqFZfBKmERhjgEd', 'pt', {
                    username: $rootScope.Login.email,
                    email: $rootScope.Login.email,
                    firstname: $rootScope.Login.nome,
                    lastname: $rootScope.Login.sobrenome,
                    props: {
                        Plano: $rootScope.Plano,
                        Superlogica: $rootScope.codigoSuperlogica,
                        Empresa: $rootScope.empresaLogado,
                    }
                }]);
            $scope.consultarCreditos($rootScope.IDCrypto, _login, _dados);
            $http({
                method: "GET",
                url: "/integracoes/consultarIntegracoes",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultaIntegracoes) {
                if (rConsultaIntegracoes.data) {
                    rConsultaIntegracoes.data.forEach(function (rIntegracoes) {
                        if (rIntegracoes.application == "E") {
                            _integracaoPreVendaLogin = rIntegracoes.nomeAplicao;
                            $rootScope.intPreVendaLogin = rIntegracoes;
                        }
                        if (rIntegracoes.application == "R") {
                            _integracaoInboundLogin = rIntegracoes.nomeAplicao;
                            $rootScope.intInboundLogin = rIntegracoes;
                        }
                        if (rIntegracoes.application != "R" &&
                            rIntegracoes.application != "E" &&
                            rIntegracoes.application != "TV") {
                            _integracaoCRMLogin = rIntegracoes.nomeAplicao;
                            $rootScope.intCrmLogin = rIntegracoes;
                        }
                    });
                }
            });
            if ($rootScope.Login.fotoPerfil && $rootScope.Login.fotoPerfil != null) {
                $http({
                    method: "GET",
                    url: "/aws/downloaderAWS",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        tipo: "fotos",
                        nome: $rootScope.Login.fotoPerfil,
                    },
                }).then(function (response) {
                    $rootScope.fotoPerfilUrl = response.data;
                });
            }
            else {
                $rootScope.fotoPerfilUrl = null;
            }
            if ($rootScope.showBrowserLogins.length == 0) {
                var _cookieAgencia = "loginAgencia_ramperv3_2018";
                var _id = lerCookie(_cookieAgencia);
                var usuario = null;
                if (_id != null && _id != "") {
                    usuario = {
                        iDCrypto: _id.split("EMA_")[1].split("_")[0],
                        objectIdLogin: _id.split("EMA_")[1].split("_")[1],
                        email: _id.split("EMA_")[1].split("_")[2],
                        fotoPerfil: _id.split("EMA_")[1].split("_")[3],
                        nome: _id.split("EMA_")[1].split("_")[4],
                        empresa: _id.split("EMA_")[1].split("_")[5],
                    };
                    if (usuario.fotoPerfil && usuario.fotoPerfil != "") {
                        usuario.fotoPerfil =
                            "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") +
                                usuario.objectIdLogin.toString() +
                                "/" +
                                usuario.fotoPerfil;
                    }
                    else {
                        usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                    }
                    if ($rootScope.Login.email !== usuario.email)
                        $rootScope.showBrowserLogins.push(usuario);
                }
                AgenciaService.consultarAgenciasLogins(usuario);
            }
            _dados.aliasconfiguracoesemails = null;
            _dados.aliastimeusuarios = null;
            $rootScope.DadosCadastrais = _dados;
            $rootScope.nomeUsuarioInicio = $rootScope.DadosCadastrais.nome;
            $rootScope.nomeUsuarioLogado =
                $rootScope.DadosCadastrais.nome +
                    " " +
                    $rootScope.DadosCadastrais.sobrenome;
            $rootScope.empresaLogado = $rootScope.DadosCadastrais.empresa;
            $rootScope.emailUsuario = _login.login.email.toString();
            $rootScope.empresaUsuario = _dados.empresa;
            $rootScope.administrativoOuVisao = $rootScope.Tipo != "U";
            var _cookie = "login_ramperv3_1910_1989_2017";
            apagarCookie(_cookie);
            gravarCookie(_cookie, "EMA_" +
                _login._idcrypto +
                "_" +
                _login.login.email.toString() +
                "_" +
                _login.login.tipo.toString(), 1);
            var _cookieExtensao = "ramperExtensao_1910_1989_2017";
            apagarCookie(_cookieExtensao, ".ramper.com.br");
            gravarCookie(_cookieExtensao, "USU_" +
                $rootScope.DadosCadastrais.objectIdLogin +
                "#EMA_" +
                $rootScope.Login.email +
                "#SEN_" +
                $rootScope.Login.senha +
                "#LIS_" +
                "" +
                "#NOM_" +
                $rootScope.nomeUsuarioLogado +
                "#TIP_" +
                $rootScope.Tipo +
                "#CON_" +
                ($rootScope.Login.parametrosConexao ? true : false), 92, ".ramper.com.br");
            $scope.consultarCsResponsavel($rootScope.IDCrypto);
            MovideskService.salvarDadosMovidesk();
            window.movideskChatWidget.show();
            $scope.loaderAtivo = false;
            $location.search("token", null);
            $location.path("/inicio");
        };
        ssoLogin();
    },
]);
